////////////////////////////////////////////////////////////////////////////////
// Heading
////////////////////////////////////////////////////////////////////////////////

@use "fonts" as font;

/// @see https://github.com/marknotton/doggistyle/blob/master/library/src/create/_headings.scss
/// <h> tags are used all over the place and tend to have bespoke style depending
/// it's semantic importance. This is why a <h2> on one page may not be the same 
/// size as a <h2> on another page. It can be tempating to hardcode a new 
/// font-size value on a case-by-case bases, usuaully to match the designs. 
/// However designs will change over time and client requests effect font flows frequently.
/// To encourage a strong continuity across the site, I propose we use the same 
/// six heading types regardless of their tag number. This will save any
/// guesswork with line-heights aswell. 
/// So if we '@include heading-h2' on a <h4>, we still know other unmodified <h2>'s 
/// will appear the same as this specific <h4>. 
/// @example 
/// @include heading-h1;
/// @include heading-h2;
/// @include heading-h3;
/// @include heading-h4;
/// @include heading-h5;
/// @include heading-h6;
/// Pass in 'true' param if you only want to define the font-size and no other properties (line-height & cropping)
/// @example 
/// h1 { @include heading-h3 } <- This H1 will look like a H3
/// h2 { @include heading-h4 } <- This H2 will look like a H4
///
/// @output
/// Also these variables are available too should you need them (hopefully you won't). 
/// $heading-h1-font-size, $heading-h1-line-height;
/// $heading-h2-font-size, $heading-h2-line-height;
/// $heading-h3-font-size, $heading-h3-line-height;
/// $heading-h4-font-size, $heading-h4-line-height;
/// $heading-h5-font-size, $heading-h5-line-height;
/// $heading-h6-font-size, $heading-h6-line-height;
///
/// Type cropping addreses spacing issues that effect differnt font files
/// at varying font sizes and line heights. This mixin attempts to fix that. 
/// @see http://text-crop.eightshapes.com/?typeface-selection=google-font&typeface=Lato&custom-typeface-name=Helvetica&custom-typeface-url=&custom-typeface-weight=400&custom-typeface-style=normal&weight-and-style=100&size=36&line-height=1.2&top-crop=9&bottom-crop=8


@forward 'create/headings'with (
	$h1 : ('font-size' : font.$size-h1,
		'font-size-min' : font.$size-h1-min,
		'line-height' : 1.2,
		'scaler' : 5vw,
		//'crop-top'    : 17,
		//'crop-bottom' : 0
	),
	$h2 : ('font-size' : font.$size-h2,
		'line-height' : 1.3,
		//'crop-top'    : 14,
		//'crop-bottom' : 5
	),
	$h3 : ('font-size' : font.$size-h3,
		'line-height' : font.$line-height,
		//'crop-top'    : 16,
		//'crop-bottom' : 1
	),
	$h4 : ('font-size' : font.$size-h4,
		'line-height' : font.$line-height,
		//'crop-top'    : 14,
		//'crop-bottom' : 3
	),
	$h5 : ('font-size' : font.$size-h5,
		'line-height' : font.$line-height,
		//'crop-top'    : 14,
		//'crop-bottom' : 3
	),
	$h6 : ('font-size' : font.$size-h6,
		'line-height' : font.$line-height,
		//'crop-top'    : 14,
		//'crop-bottom' : 3
	)
);

@mixin all() {
	h1, h2, h3, h4, h6, h6 {
		@content;
	}
}