html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em,
img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

:root {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

*, *:after, *:before {
  box-sizing: inherit;
}
*:focus, *:after:focus, *:before:focus {
  outline: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
  block-size: 100%;
  inline-size: 100%;
}

body {
  min-block-size: 100%;
  min-inline-size: 100%;
}

html, body {
  min-height: 100%;
}

p:empty, pre:empty, blockquote:empty, ol:empty, ul:empty, li:empty, legend:empty, figcaption:empty, body:empty, article:empty, nav:empty, aside:empty, section:empty, header:empty, footer:empty, address:empty {
  display: none;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

svg, symbol {
  overflow: visible;
}

details {
  display: block;
}

summary {
  display: list-item;
  -webkit-appearance: none;
}
summary::-webkit-details-marker, summary::marker {
  display: none;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}

address {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  font-weight: normal;
}

hr {
  border: none;
  height: 1px;
  box-sizing: content-box;
  overflow: visible;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
  background-color: white;
  color: black;
  padding: 16px;
  position: relative;
  z-index: 9999;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  touch-action: manipulation;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
button:focus,
[type=button]:focus,
[type=reset]:focus,
[type=submit]:focus {
  outline: 0;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[disabled] {
  cursor: default;
}

img {
  border-style: none;
}

em {
  font-style: italic;
}

a {
  background-color: transparent;
  text-decoration: none;
  touch-action: manipulation;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bold;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

textarea {
  resize: none;
  overflow: auto;
}

pre {
  background-color: white;
  color: black;
  text-transform: none;
  text-decoration: none;
  text-align: left;
  display: block;
  font-size: 16px;
  padding: 16px;
  position: relative;
  z-index: 999;
}

body.devmode img:not([alt]) {
  border: 2px red dashed;
}

/* z-indexes are large due to the fact that things might change
 * in the future. In order to account for scalability, this gives
 * room to make changes and not break the entire site, meaning 
 * we won't have to go in and refactor a lot of our code.
 */
/*  ----------------------------------------------------
    INPUTS
  ----------------------------------------------------- */
/*  ----------------------------------------------------
    CONTAINERS
  ----------------------------------------------------- */
/* -------------------
	Base result styles
-------------------- */
/* -------------------
	Variations
-------------------- */
/* -----------------------------------------------------
  Base styling
----------------------------------------------------- */
/* -----------------------------------------------------
  With secondary section
----------------------------------------------------- */
/* -----------------------------------------------------
  Checkbox tags
----------------------------------------------------- */
/* -----------------------------------------------------
  States
----------------------------------------------------- */
@font-face {
  font-family: "lato";
  src: url("../../../assets/fonts/lato-bold.woff2") format("woff2"), url("../../../assets/fonts/lato-bold.woff") format("woff"), url("../../../assets/fonts/lato-bold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "lato";
  src: url("../../../assets/fonts/lato-semibold.woff2") format("woff2"), url("../../../assets/fonts/lato-semibold.woff") format("woff"), url("../../../assets/fonts/lato-semibold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "lato";
  src: url("../../../assets/fonts/lato-regular.woff2") format("woff2"), url("../../../assets/fonts/lato-regular.woff") format("woff"), url("../../../assets/fonts/lato-regular.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "montserrat";
  src: url("../../../assets/fonts/montserrat-medium.woff2") format("woff2"), url("../../../assets/fonts/montserrat-medium.woff") format("woff"), url("../../../assets/fonts/montserrat-medium.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "montserrat";
  src: url("../../../assets/fonts/montserrat-medium-italic.woff2") format("woff2"), url("../../../assets/fonts/montserrat-medium-italic.woff") format("woff"), url("../../../assets/fonts/montserrat-medium-italic.otf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "montserrat";
  src: url("../../../assets/fonts/montserrat-semibold.woff2") format("woff2"), url("../../../assets/fonts/montserrat-semibold.woff") format("woff"), url("../../../assets/fonts/montserrat-semibold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "montserrat";
  src: url("../../../assets/fonts/montserrat-regular.woff2") format("woff2"), url("../../../assets/fonts/montserrat-regular.woff") format("woff"), url("../../../assets/fonts/montserrat-regular.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "montserrat";
  src: url("../../../assets/fonts/montserrat-extrabold.woff2") format("woff2"), url("../../../assets/fonts/montserrat-extrabold.woff") format("woff"), url("../../../assets/fonts/montserrat-extrabold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}
:root {
  --primary: #FFFFFF;
  --secondary: #131f6b;
  --breakpoint-min: 320px;
  --breakpoint-small: 480px;
  --breakpoint-small-medium: 980px;
  --breakpoint-medium: 1120px;
  --breakpoint-large: 1400px;
  --breakpoint-max: 1680px;
  --font-colour: var(--theme-font, #6A6A6A);
  --heading-colour: var(--theme-heading, #FFFFFF);
  --sub-heading-colour: var(--secondary, #131f6b);
  --transition-duration: 0.2s;
  --transition-ease: cubic-bezier(0.42, 0, 0.58, 1);
  --transition-delay: ;
  --gap: 16px;
  --gutter: 3vw;
  --spacer: 4vh;
  --gutter0: clamp(8px, var(--gutter) * 0.5, 12px);
  --gutter0-: calc(-1 * clamp(8px, var(--gutter) * 0.5, 12px));
  --spacer0: clamp(16px, var(--spacer), 32px);
  --spacer0-: calc(-1 * clamp(16px, var(--spacer), 32px));
  --gutter1: clamp(16px, var(--gutter), 32px);
  --gutter1-: calc(-1 * clamp(16px, var(--gutter), 32px));
  --spacer1: clamp(16px, var(--spacer) * 3, 64px);
  --spacer1-: calc(-1 * clamp(16px, var(--spacer) * 3, 64px));
  --gutter2: clamp(16px, var(--gutter) * 2, 48px);
  --gutter2-: calc(-1 * clamp(16px, var(--gutter) * 2, 48px));
  --spacer2: clamp(16px, var(--spacer) * 6, 112px);
  --spacer2-: calc(-1 * clamp(16px, var(--spacer) * 6, 112px));
  --gutter3: clamp(16px, var(--gutter) * 3, 64px);
  --gutter3-: calc(-1 * clamp(16px, var(--gutter) * 3, 64px));
  --spacer3: clamp(16px, var(--spacer) * 9, 160px);
  --spacer3-: calc(-1 * clamp(16px, var(--spacer) * 9, 160px));
  --gutter4: clamp(16px, var(--gutter) * 4, 80px);
  --gutter4-: calc(-1 * clamp(16px, var(--gutter) * 4, 80px));
  --spacer4: clamp(16px, var(--spacer) * 12, 208px);
  --spacer4-: calc(-1 * clamp(16px, var(--spacer) * 12, 208px));
  --gutter5: clamp(16px, var(--gutter) * 5, 96px);
  --gutter5-: calc(-1 * clamp(16px, var(--gutter) * 5, 96px));
  --spacer5: clamp(16px, var(--spacer) * 15, 256px);
  --spacer5-: calc(-1 * clamp(16px, var(--spacer) * 15, 256px));
  --gutter6: clamp(16px, var(--gutter) * 6, 112px);
  --gutter6-: calc(-1 * clamp(16px, var(--gutter) * 6, 112px));
  --spacer6: clamp(16px, var(--spacer) * 18, 304px);
  --spacer6-: calc(-1 * clamp(16px, var(--spacer) * 18, 304px));
}
:root body[data-theme=default] {
  --theme-page-background: linear-gradient(to bottom right, #131f6b 5%, #1E1A1A 66%);
  --theme-content-background: rgba(255, 255, 255, 0);
  --theme-content-opposite: #FFFFFF;
  --theme-nav-background: rgba(255, 255, 255, 0);
  --theme-nav-background-mob: #FFFFFF;
  --theme-nav-font: #FFFFFF;
  --theme-nav-font-mob: #131f6b;
  --theme-nav-spacing-colour: rgba(19, 31, 107, 0.3);
  --theme-accent: #DF3035;
  --theme-logo: #FFFFFF;
  --font-colour: #FFFFFF;
  --heading-colour: #FFFFFF;
}
:root body[data-theme=white] {
  --theme-page-background: radial-gradient(circle at bottom, #10124d 55%, #172582 100%);
  --theme-content-background: #f8f8f8;
  --theme-content-opposite: #FFFFFF;
  --theme-nav-background: rgba(255, 255, 255, 0);
  --theme-nav-background-mob: #131f6b;
  --theme-nav-font: #FFFFFF;
  --theme-nav-font-mob: #FFFFFF;
  --theme-nav-spacing-colour: rgba(255, 255, 255, 0.3);
  --theme-accent: #DF3035;
  --theme-logo: #FFFFFF;
  --font-colour: #1E1A1A;
  --heading-colour: #131f6b;
}
:root body[data-theme=black] {
  --theme-page-background: linear-gradient(to bottom right, #131f6b 5%, #1E1A1A 66%);
  --theme-content-background: #1E1A1A;
  --theme-content-opposite: #FFFFFF;
  --theme-nav-background: #FFFFFF;
  --theme-nav-background-mob: #FFFFFF;
  --theme-nav-font: #131f6b;
  --theme-nav-font-mob: #131f6b;
  --theme-nav-spacing-colour: rgba(19, 31, 107, 0.3);
  --theme-font: #FFFFFF;
  --theme-accent: #DF3035;
  --theme-logo: #131f6b;
  --font-colour: #FFFFFF;
  --heading-colour: #FFFFFF;
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  :root {
    --gutter0: calc((var(--gutter) * 0.5) + 8px);
    --gutter0-: calc(-1 * calc((var(--gutter) * 0.5) + 8px));
    --spacer0: calc((var(--spacer) * 2) + 16px);
    --spacer0-: calc(-1 * calc((var(--spacer) * 2) + 16px));
    --gutter1: calc((var(--gutter) * 1) + 16px);
    --gutter1-: calc(-1 * calc((var(--gutter) * 1) + 16px));
    --spacer1: calc((var(--spacer) * 4) + 16px);
    --spacer1-: calc(-1 * calc((var(--spacer) * 4) + 16px));
    --gutter2: calc((var(--gutter) * 2) + 16px);
    --gutter2-: calc(-1 * calc((var(--gutter) * 2) + 16px));
    --spacer2: calc((var(--spacer) * 8) + 16px);
    --spacer2-: calc(-1 * calc((var(--spacer) * 8) + 16px));
    --gutter3: calc((var(--gutter) * 3) + 16px);
    --gutter3-: calc(-1 * calc((var(--gutter) * 3) + 16px));
    --spacer3: calc((var(--spacer) * 12) + 16px);
    --spacer3-: calc(-1 * calc((var(--spacer) * 12) + 16px));
    --gutter4: calc((var(--gutter) * 4) + 16px);
    --gutter4-: calc(-1 * calc((var(--gutter) * 4) + 16px));
    --spacer4: calc((var(--spacer) * 16) + 16px);
    --spacer4-: calc(-1 * calc((var(--spacer) * 16) + 16px));
    --gutter5: calc((var(--gutter) * 5) + 16px);
    --gutter5-: calc(-1 * calc((var(--gutter) * 5) + 16px));
    --spacer5: calc((var(--spacer) * 20) + 16px);
    --spacer5-: calc(-1 * calc((var(--spacer) * 20) + 16px));
    --gutter6: calc((var(--gutter) * 6) + 16px);
    --gutter6-: calc(-1 * calc((var(--gutter) * 6) + 16px));
    --spacer6: calc((var(--spacer) * 24) + 16px);
    --spacer6-: calc(-1 * calc((var(--spacer) * 24) + 16px));
  }
}

body {
  font-family: montserrat, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-colour, #6A6A6A);
  line-height: 1.6;
  font-size: 1.5rem;
}
@supports (width: clamp(1px, 5vw, 10px)) {
  body {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
  }
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  @media (max-width: 950px) {
    body {
      font-size: 1.4rem;
    }
  }
  @media (min-width: 1601px) {
    body {
      font-size: 1.6rem;
    }
  }
}

h1 {
  font-size: 3.125em;
  font-size: clamp(2.5em, 5vw, 3.125em);
  line-height: 1.2;
}

h2 {
  font-size: 1.25em;
  line-height: 1.3;
}

h3 {
  font-size: 0.9375em;
  line-height: 1.6;
}

h4 {
  font-size: 0.8125em;
  line-height: 1.6;
}

h5 {
  font-size: 0.8125em;
  line-height: 1.6;
}

h6 {
  font-size: 0.8125em;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: montserrat, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--heading-colour, var(--font-colour));
}
h1.sub-heading, h2.sub-heading, h3.sub-heading, h4.sub-heading, h5.sub-heading, h6.sub-heading {
  color: var(--sub-heading-colour);
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 span, h1 strong, h1 p, h2 span, h2 strong, h2 p, h3 span, h3 strong, h3 p, h4 span, h4 strong, h4 p, h5 span, h5 strong, h5 p, h6 span, h6 strong, h6 p {
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}

a {
  cursor: pointer;
  transition: text-decoration-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: rgba(255, 255, 255, 0);
}
a.external {
  display: inline-flex;
  align-items: center;
}
a.external svg.external-link {
  opacity: 0.6;
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.5em;
}
a:hover {
  text-decoration-color: var(--font-colour);
}

@media (hover: hover) {
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h6 a:hover, h6 a:hover {
    text-decoration-color: var(--heading-colour);
  }
}

p, a {
  color: var(--font-colour);
}

ol, ul {
  list-style: none;
  list-style-position: inside;
}

li a {
  text-underline-offset: 2px;
}

small {
  font-size: 0.625em;
  line-height: 1.8;
}

hr {
  width: 100%;
  background-color: var(--hr-colour, #B1B1B1);
  margin-top: calc(var(--gap) * 2);
  margin-bottom: calc(var(--gap) * 2);
}

html {
  background-color: black;
}

body {
  --navigation-bar-width: clamp(250px, 25vw, 300px);
  --navigation-bar-height: 100vh;
  --sidebar-width: 30vw;
  --sidebar-max-width: var(--sidebar-width);
  --logo-height: 200px;
  --gap-x: clamp(2rem, 5vw, 8rem);
  --gap-y: 50px;
  --row-content: 1 / -1;
  --row-header: calc(1 + var(--content-offset, 0));
  --row-main: calc(2 + var(--content-offset, 0));
  --column-navigation: 1;
  --column-content: 2;
  --column-header: 2;
  --column-main: 2;
  --column-sidebar: 3;
  --rows: var(--logo-height) auto 1fr;
  --columns: var(--navigation-bar-width) minmax(0, 1fr) auto;
  display: grid;
  grid-gap: var(--gap-y) var(--gap-x);
  grid-template-rows: var(--rows);
  grid-template-columns: var(--columns);
}
body[data-sidebar=top] {
  --row-sidebar: 1 / -1;
}
body[data-sidebar=header] {
  --row-sidebar: var(--row-header) / -2;
}
body[data-sidebar=content] {
  --row-sidebar: var(--row-main) / -2;
}
body navigation-bar {
  grid-row: var(--row-navigation);
  grid-column: var(--column-navigation);
}
body .content-wrapper {
  grid-row: var(--row-content);
  grid-column: var(--column-content);
  z-index: 1000;
}
body side-bar {
  grid-row: var(--row-sidebar);
  grid-column: var(--column-sidebar);
}
body.heatsinks-home, body.components-home {
  --row-content: 2;
}
@media (max-width: 1400px) {
  body[data-sidebar=top], body[data-sidebar=header], body[data-sidebar=content] {
    --column-sidebar: 2;
    --row-content: 1 / -2;
  }
  body[data-sidebar=top] side-bar, body[data-sidebar=header] side-bar, body[data-sidebar=content] side-bar {
    --row-sidebar: calc(var(--row-content) + 3);
  }
}
@media (max-width: 980px) {
  body {
    place-content: start;
    --navigation-container-height: 60px;
    --navigation-mobile-search-height: 61px;
    --rows: auto auto 1fr auto;
    --columns: 0 minmax(0, 1fr) 0;
    --row-navigation: 1;
    --column-navigation: 1 / -1;
  }
  body:not(.heatsinks-home, .components-home) {
    --row-content: 2 / span 3 !important;
  }
  body > * {
    width: 100%;
  }
  body main {
    margin-top: -2rem;
  }
}
body.mobile-open, body.modal-open {
  overflow: hidden;
}

.content {
  line-height: 1.7;
}
.content ul > li::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='%23DA4B0F' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='white' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
}
.content ul > li {
  position: relative;
  padding-left: calc(var(--list-style-size, 1em) * 1.8);
}
.content ul > li + li {
  margin-top: var(--line-style-spacing, calc(var(--gap) * 0.5));
}
.content ul > li::before {
  display: inline-block;
  top: 0.1em;
  left: 0;
  position: absolute;
  width: var(--list-style-size, 1em);
  height: var(--list-style-size, 1em);
}
.content ol {
  counter-reset: counter;
}
.content ol > li {
  position: relative;
  display: block;
  padding-left: 22px;
  margin-left: var(--list-style-offset, 0);
}
.content ol > li + li {
  margin-top: calc(var(--gap) * 0.5);
}
.content ol > li::before {
  content: counter(counter) ".";
  counter-increment: counter;
  color: var(--list-style-colour, var(--font-colour));
  position: relative;
  margin-right: 0.5em;
}
.content h3 {
  font-size: 1.25em;
  line-height: 1.3;
}
.content > * {
  margin-bottom: 3rem;
}
.content > *:last-child {
  margin-bottom: 0;
}
.content > ul, .content > ol {
  margin-bottom: 4rem;
}
.content h1, .content h2, .content h3, .content h4, .content h6, .content h6 {
  margin-bottom: 2.25rem;
}
.content h1 + h1, .content h1 + h2, .content h1 + h3, .content h1 + h4, .content h1 + h5, .content h1 + h6, .content h2 + h1, .content h2 + h2, .content h2 + h3, .content h2 + h4, .content h2 + h5, .content h2 + h6, .content h3 + h1, .content h3 + h2, .content h3 + h3, .content h3 + h4, .content h3 + h5, .content h3 + h6, .content h4 + h1, .content h4 + h2, .content h4 + h3, .content h4 + h4, .content h4 + h5, .content h4 + h6, .content h6 + h1, .content h6 + h2, .content h6 + h3, .content h6 + h4, .content h6 + h5, .content h6 + h6, .content h6 + h1, .content h6 + h2, .content h6 + h3, .content h6 + h4, .content h6 + h5, .content h6 + h6 {
  margin-top: 3rem;
}
.content a {
  --font-colour: var(--theme-accent) ;
}

input[type=text], input[type=email], input[type=number], input[type=search], select, textarea {
  --font-colour: #6A6A6A;
  --background-colour: #FFFFFF;
  --padding: calc(var(--gap) / 1.5) var(--gap);
  --border-colour: #e9e9e9;
  --border-radius: 0;
  outline: 0;
  border: 1px solid var(--border-colour);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: var(--border-radius, 6px);
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  -webkit-border-radius: 0;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -moz-border-radius: 0;
  /* Firefox 1-3.6 */
  color: var(--font-colour);
  background-color: var(--background-colour);
  padding: var(--padding);
  transition: box-shadow var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  box-shadow: 0px 0px 0px 0px transparent;
}
input[type=text]::placeholder, input[type=email]::placeholder, input[type=number]::placeholder, input[type=search]::placeholder, select::placeholder, textarea::placeholder {
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
input[type=text]:not(select), input[type=email]:not(select), input[type=number]:not(select), input[type=search]:not(select), select:not(select), textarea:not(select) {
  width: 100%;
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  display: none;
  -webkit-appearance: none;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=search]:focus,
select:focus,
textarea:focus {
  box-shadow: 0px 0px 1px 1px rgba(223, 48, 53, 0.3);
}

input[type=submit], button[type=submit] {
  --background-colour: #DF3035;
  --font-colour: white;
  --icon-size: 0.8em;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  grid-gap: calc(var(--gap) * 0.5);
  align-items: center;
  word-break: break-all;
  white-space: nowrap;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  overflow: hidden;
  height: fit-content;
  padding: 1.25rem 3rem 1.25rem 4rem;
  font-size: 16px;
  background: var(--background-colour);
  color: var(--font-colour);
  clip-path: polygon(0% 100%, 15px 0%, 100% 0%, 100% 100%);
}
input[type=submit] > svg, button[type=submit] > svg {
  width: var(--icon-size);
  height: var(--icon-size);
}
input[type=submit][disabled], button[type=submit][disabled] {
  opacity: 0.4;
  pointer-events: none;
}
input[type=submit]:not([disabled]):hover, button[type=submit]:not([disabled]):hover {
  text-decoration-line: none;
  text-decoration-color: transparent;
}
input[type=submit]:not([disabled]):hover::before, button[type=submit]:not([disabled]):hover::before {
  transform: translateX(calc(100% - 15px));
}
input[type=submit].small, button[type=submit].small {
  padding: 0.9rem 2rem 0.9rem 3rem;
  font-size: 12px;
}
input[type=submit].large, button[type=submit].large {
  padding: 1.4rem 3rem 1.4rem 4.25rem;
  font-size: 18px;
}
input[type=submit]::before, button[type=submit]::before {
  --transition-duration: 0.3s;
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: calc(100% + 15px);
  height: 100%;
  background: var(--background-colour);
  filter: brightness(120%);
  clip-path: polygon(0% 100%, 0% 0%, 100% 0%, calc(100% - 15px) 100%);
  z-index: -1;
  transition: transform var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}

select {
  text-transform: uppercase;
  padding-right: calc(var(--gap) * 3);
  color: var(--font-colour);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.4' height='10.1'%3E%3Cpath d='M16.7.7l-8 8-8-8' fill='none' stroke='%236A6A6A' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: calc(100% - var(--gap)) center;
  width: min-content;
}
select option {
  text-transform: uppercase;
  font-weight: 400;
}

.input-container.horizontal {
  grid-auto-flow: column !important;
  align-items: center;
  justify-content: space-between;
}
.input-container:not(.radio, .checkbox) {
  --label-colour: var(--font-colour);
  --label-active-colour: var(--theme-accent);
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: row;
}
.input-container:not(.radio, .checkbox) label {
  color: var(--label-colour);
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.input-container:not(.radio, .checkbox) input[maxlength], .input-container:not(.radio, .checkbox) input[size] {
  width: fit-content;
}
.input-container.radio, .input-container.checkbox {
  --label-colour: var(--font-colour);
  --label-active-colour: var(--theme-accent);
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  --input-width: 1.2em;
  --input-border-thickness: 1px;
  --input-active-colour: #DF3035;
}
.input-container.radio label, .input-container.checkbox label {
  color: var(--label-colour);
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.input-container.radio label, .input-container.checkbox label {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--gap);
  align-items: center;
  width: fit-content;
}
.input-container.radio label::before, .input-container.radio label::after, .input-container.checkbox label::before, .input-container.checkbox label::after {
  grid-row: 1;
  grid-column: 1;
  content: "";
  position: relative;
  display: block;
}
.input-container.radio label::before, .input-container.checkbox label::before {
  width: var(--input-width);
  height: var(--input-width);
  background: #FFFFFF;
  border: var(--input-border-thickness, 1px) solid #969695;
  transition: border-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  cursor: pointer;
}
.input-container.radio label::after, .input-container.checkbox label::after {
  width: calc(var(--input-width) - var(--input-border-thickness) * 6);
  height: calc(var(--input-width) - var(--input-border-thickness) * 6);
  background: var(--input-active-colour);
  opacity: 0;
  left: -10px;
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  pointer-events: none;
  justify-self: center;
}
.input-container.radio label:hover, .input-container.checkbox label:hover {
  --label-colour: var(--label-active-colour);
}
.input-container.radio label:hover::before, .input-container.checkbox label:hover::before {
  border-color: var(--input-active-colour);
}
.input-container.radio input, .input-container.checkbox input {
  display: none;
}
.input-container.radio input:checked + label, .input-container.checkbox input:checked + label {
  color: var(--label-active-colour);
}
.input-container.radio input:checked + label::after, .input-container.checkbox input:checked + label::after {
  opacity: 1;
  transform: translateX(10px);
}
.input-container.radio label::before, .input-container.radio label::after {
  border-radius: 50%;
}

.multi-input {
  --input-spacing: 0;
  display: flex;
  gap: var(--input-spacing);
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
}
.multi-input input[type=text]:not(select), .multi-input input[type=email]:not(select), .multi-input input[type=number]:not(select), .multi-input input[type=search]:not(select), .multi-input select:not(select), .multi-input textarea:not(select) {
  border: 1px solid var(--border-colour);
  border-right: 0;
}
.multi-input > *:not(select, button, a) {
  flex: 1;
}
.multi-input select, .multi-input button, .multi-input a {
  --font-colour: #FFFFFF;
  --background-colour: #DF3035;
  text-transform: uppercase;
  border-left: 1px solid var(--border-colour);
  background-color: var(--background-colour);
  color: var(--font-colour);
  font-weight: 600;
}
.multi-input select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.4' height='10.1'%3E%3Cpath d='M16.7.7l-8 8-8-8' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");
}

radio-tabs {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
radio-tabs radio-tab {
  position: relative;
  display: block;
  font-weight: 400;
  text-transform: uppercase;
  padding-bottom: calc(var(--gap) * 0.5);
}
radio-tabs radio-tab input {
  display: none;
}
radio-tabs radio-tab input:checked + label {
  font-weight: 600;
}
radio-tabs radio-tab input:checked + label::after {
  background: var(--theme-accent);
}
radio-tabs radio-tab input:not(:checked) + label:hover::after {
  background: var(--theme-accent);
}
radio-tabs radio-tab label {
  color: var(--font-colour);
  cursor: pointer;
}
radio-tabs radio-tab label::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0);
  transition: background var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}

form [type=submit] {
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
form:invalid [type=submit] {
  cursor: not-allowed;
  opacity: 0.5;
}

form[data-freeform] h3, form[data-freeform] h4, form[data-freeform] h5, form[data-freeform] h6 {
  font-size: 1.25em;
  line-height: 1.3;
}
form[data-freeform] .ff-form-success {
  --font-colour: #50BC08;
  margin-bottom: calc(var(--gap) * 2);
}
form[data-freeform] .ff-form-success p {
  display: flex;
  align-items: center;
}
form[data-freeform] .ff-form-success p::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='%2350BC08' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='white' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
  display: block;
  margin-right: var(--gap);
  width: 1.25em;
  height: 1.25em;
}
form[data-freeform] .ff-form-errors {
  --font-colour: #DF3035;
  margin-bottom: calc(var(--gap) * 2);
}
form[data-freeform] .ff-errors {
  --font-colour: #DF3035;
  margin-top: var(--gap);
  color: var(--font-colour);
}
form[data-freeform] .form-fields {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: var(--gap);
}
form[data-freeform] .form-fields .freeform-row {
  display: grid;
  grid-gap: 2rem;
  grid-auto-flow: column;
  height: fit-content;
}
form[data-freeform] .form-fields .freeform-row .freeform-column {
  position: relative;
}
form[data-freeform] .form-fields .freeform-row .freeform-column h3, form[data-freeform] .form-fields .freeform-row .freeform-column h4, form[data-freeform] .form-fields .freeform-row .freeform-column h5, form[data-freeform] .form-fields .freeform-row .freeform-column h6 {
  margin-top: calc(var(--gap) * 2);
}
form[data-freeform] .form-fields .freeform-row .freeform-column.freeform-column-content-align-right {
  text-align: right;
}
form[data-freeform] .form-fields .freeform-row .freeform-column label:not(.freeform-input-only-label) {
  opacity: 0.7;
}
form[data-freeform] .form-fields .freeform-row .freeform-column label:not(.freeform-input-only-label) + .freeform-input {
  margin-top: calc(var(--gap) / 2);
}
form[data-freeform] .form-fields required-notice {
  --notice-colour: #DF3035;
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  color: var(--notice-colour);
  font-size: 1.5em;
}
form[data-freeform] .form-fields required-notice span {
  position: absolute;
  right: -100%;
  pointer-events: none;
  white-space: nowrap;
  font-size: 1.5rem;
  opacity: 0;
}
form[data-handle=contact] {
  --submit-height: 41px;
}
form[data-handle=contact] .form-fields label:not(.freeform-input-only-label) {
  text-transform: uppercase;
}
form[data-handle=contact] .form-fields .message, form[data-handle=contact] .form-fields .submit-row {
  grid-row: 4;
  grid-column: 1;
}
form[data-handle=contact] .form-fields .message .freeform-column {
  display: grid;
}
form[data-handle=contact] .form-fields textarea[name=message] {
  z-index: 1;
  padding-bottom: calc(41px + var(--gap));
}
form[data-handle=contact] .form-fields .submit-row {
  width: 100%;
  z-index: 1;
  margin: auto 0 0 auto;
}
form[data-handle=contact] .form-fields .submit-row button {
  margin-left: auto;
  z-index: 2;
}
form[data-handle=creditApplication] .freeform-row:nth-last-of-type(-n+2), form[data-handle=creditApplication] .freeform-row:nth-last-of-type(-n+1) {
  margin-top: 2rem;
}
form[data-handle=creditApplication] .freeform-row:last-of-type {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: initial;
  background-color: var(--theme-content-background);
}
body#search .subheading {
  margin-right: 2rem;
}
body#search .subheading u {
  white-space: pre-line;
  word-break: break-word;
}

.d-none {
  display: none !important;
}

figure.image, picture.image {
  display: block;
  background-position: var(--position, center 33%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}
figure.image img, picture.image img {
  display: block;
  position: absolute;
  object-fit: cover;
  object-position: var(--image-position, center 33.33%);
  width: var(--image-size, var(--image-width-size, 100%));
  height: var(--image-size, var(--image-height-size, 100%));
  max-width: var(--image-size, var(--image-width-size, 100%));
  max-height: var(--image-size, var(--image-height-size, 100%));
  top: 0;
  left: 0;
}

video-container {
  --aspect-ratio: 16/9;
  aspect-ratio: var(--aspect-ratio);
  display: block;
  position: relative;
  width: 100%;
  background-color: black;
}
@supports not (aspect-ratio: 16/9) {
  video-container {
    aspect-ratio: initial;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    height: 0;
  }
  video-container > * {
    position: absolute;
  }
}
video-container iframe, video-container canvas {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
}

mark {
  background-color: #0095FF;
  color: white;
  border-radius: calc(var(--border-radius, 6px) / 2);
  padding: 0 5px;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}

details {
  outline: none;
}
details[open] summary > svg {
  transform: rotate(90deg);
}

summary {
  -webkit-appearance: none;
  cursor: pointer;
}
summary::-webkit-details-marker, summary::marker {
  display: none;
}

.overflow-hidden {
  overflow: hidden !important;
}

.hide {
  opacity: 0 !important;
  pointer-events: none !important;
}

.button {
  --background-colour: #DF3035;
  --font-colour: white;
  --icon-size: 0.8em;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  grid-gap: calc(var(--gap) * 0.5);
  align-items: center;
  word-break: break-all;
  white-space: nowrap;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  overflow: hidden;
  height: fit-content;
  padding: 1.25rem 3rem 1.25rem 4rem;
  font-size: 16px;
  background: var(--background-colour);
  color: var(--font-colour);
  clip-path: polygon(0% 100%, 15px 0%, 100% 0%, 100% 100%);
}
.button > svg {
  width: var(--icon-size);
  height: var(--icon-size);
}
.button[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
.button:not([disabled]):hover {
  text-decoration-line: none;
  text-decoration-color: transparent;
}
.button:not([disabled]):hover::before {
  transform: translateX(calc(100% - 15px));
}
.button.small {
  padding: 0.9rem 2rem 0.9rem 3rem;
  font-size: 12px;
}
.button.large {
  padding: 1.4rem 3rem 1.4rem 4.25rem;
  font-size: 18px;
}
.button::before {
  --transition-duration: 0.3s;
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: calc(100% + 15px);
  height: 100%;
  background: var(--background-colour);
  filter: brightness(120%);
  clip-path: polygon(0% 100%, 0% 0%, 100% 0%, calc(100% - 15px) 100%);
  z-index: -1;
  transition: transform var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}

.button svg, button svg {
  pointer-events: none;
  vertical-align: middle;
}

picture {
  display: block;
}

.result-item {
  --heading-colour: #131f6b;
  --border-thickness: 1px;
  --border-radius: 0px;
  --background-colour: white;
  --padding: 2rem;
  --padding-left: var(--padding);
  --padding-right: var(--padding);
  --padding-top: var(--padding);
  --padding-bottom: var(--padding);
  --image-width: 100px;
  --image-height: 100px;
  --grid-gap: var(--gap);
  position: relative;
  padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
  border: var(--border-thickness) solid rgba(255, 255, 255, 0);
  border-radius: var(--border-radius, 6px);
  background-color: var(--background-colour);
  overflow: hidden;
  transition: border var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  display: grid;
  grid-gap: var(--grid-gap) var(--grid-gap);
}
.result-item .result-image {
  grid-area: image;
  display: block;
}
.result-item .result-image picture {
  height: var(--image-height);
}
.result-item .result-image picture img {
  width: var(--image-height);
  height: var(--image-width);
}
.result-item .result-title {
  grid-area: title;
  color: var(--heading-colour);
}
.result-item .result-title a {
  color: var(--heading-colour);
}
.result-item .result-subtitle {
  grid-area: subtitle;
}
.result-item .result-body {
  grid-area: body;
}
.result-item.blog {
  --padding: 4rem;
  --border-thickness: 0;
  --border-radius: 0;
  grid-template-areas: "subtitle" "title" "body";
}
.result-item.blog .result-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.result-item.blog .result-image img {
  width: 100%;
  object-fit: cover;
}
.result-item.blog .result-image::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, white 45%, transparent 120%);
}
.result-item.blog .result-title, .result-item.blog .result-subtitle {
  text-transform: uppercase;
}
.result-item.blog .result-title {
  font-size: 1.25em;
  line-height: 1.3;
  line-height: 1.3;
}
.result-item.blog .result-subtitle {
  --heading-colour: #DF3035;
  display: flex;
  align-items: center;
}
.result-item.blog .result-subtitle h4, .result-item.blog .result-subtitle time {
  font-size: 1.5rem;
  height: 100%;
}
.result-item.blog .result-subtitle time {
  color: #969695;
}
.result-item.blog .result-body {
  --font-colour: #1E1A1A;
}
.result-item.blog.featured {
  --padding-right: 5rem;
  grid-column: 1/span 2;
}
.result-item.blog.featured .result-title {
  font-weight: 800;
  font-size: 2em;
}
.result-item.blog.featured::before {
  content: "FEATURED";
  position: absolute;
  top: 0;
  padding: 0.7rem 2rem 0.7rem 3rem;
  height: 39px;
  background: #ed6224;
  color: #FFFFFF;
  transform: translate(0, -0.5px) translateX(-100%) translateY(-100%) rotate(-90deg);
  transform-origin: bottom right;
  clip-path: polygon(0% 100%, 15px 0%, 100% 0%, 100% 100%);
  left: 100%;
  z-index: 1;
}
.result-item.blog .result-title, .result-item.blog .result-subtitle, .result-item.blog .result-body {
  z-index: 2;
}
.result-item.product {
  --badge-spacing: 3rem;
  grid-template-columns: var(--image-width) auto;
  grid-template-areas: "image title" "image title" "image data" "image data";
}
.result-item.product .result-title, .result-item.product .result-body {
  padding-right: calc(var(--badge-spacing) * 2);
}
.result-item.product .product-data {
  grid-area: data;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-gap: calc(var(--gap) / 2) var(--gap);
}
.result-item.product .result-image img {
  object-fit: contain;
}
@media (max-width: 450px) {
  .result-item.product {
    grid-template-areas: "image " "title" "data";
    grid-template-columns: unset;
  }
  .result-item.product .product-data {
    flex-direction: row;
  }
  .result-item.product .result-title, .result-item.product .result-body {
    padding-right: 0;
  }
}
.result-item.product.in-stock::before {
  --padding-y: 0.2em;
  --padding-x: 10em;
  content: "in stock";
  position: absolute;
  top: calc(1em + var(--padding-y));
  right: calc(-1 * var(--padding-x));
  padding: 0.5em var(--padding-x);
  transform: rotate(45deg);
  text-transform: uppercase;
  background-color: #131f6b;
  color: #FFFFFF;
}
.result-item.page {
  grid-template-areas: "image title" "image body" "image body" "image body";
}
.result-item.page .result-image img {
  object-fit: cover;
}

.tag[disabled] {
  opacity: 0.4;
  pointer-events: none;
}
.tag.category {
  --border-radius: 0px;
  --font-colour: #DF3035;
  --bg-colour: white;
  --secondary-width: 3ch;
  --secondary-padding-x: 3px;
  --border-colour: transparent;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--border-colour);
  border-radius: var(--border-radius);
  color: var(--font-colour);
  background-color: var(--bg-colour);
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: content-box;
  --border-colour: #DF3035;
  --symbol-colour: #DF3035;
}
.tag.category .tag-label {
  padding: 3px var(--gap) 3px var(--gap);
}
.tag.category .tag-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--secondary-padding-x);
  height: 100%;
  background-color: #dcdcdc;
  overflow: hidden;
  width: 3ch;
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.tag.category .tag-secondary svg {
  width: 50%;
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.tag.category .tag-secondary:hover {
  --symbol-colour: white;
  background-color: #DF3035;
}
.tag.checkbox input[type=checkbox] {
  display: none;
}
.tag.checkbox input[type=checkbox] ~ label {
  --border-radius: 0px;
  --font-colour: #DF3035;
  --bg-colour: white;
  --secondary-width: 3ch;
  --secondary-padding-x: 3px;
  --border-colour: transparent;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--border-colour);
  border-radius: var(--border-radius);
  color: var(--font-colour);
  background-color: var(--bg-colour);
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: content-box;
  --symbol-colour: #DF3035;
}
.tag.checkbox input[type=checkbox] ~ label .tag-label {
  padding: 3px var(--gap) 3px var(--gap);
}
.tag.checkbox input[type=checkbox] ~ label .tag-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--secondary-padding-x);
  height: 100%;
  background-color: #dcdcdc;
  overflow: hidden;
  width: 3ch;
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.tag.checkbox input[type=checkbox] ~ label .tag-secondary svg {
  width: 50%;
  transition: var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.tag.checkbox input[type=checkbox] ~ label:hover {
  --border-colour: #DF3035;
  cursor: pointer;
}
.tag.checkbox input[type=checkbox]:checked ~ label {
  --font-colour: white;
  --bg-colour: #DF3035;
}
.tag.checkbox input[type=checkbox]:checked ~ label .tag-secondary {
  --symbol-colour: white;
  background-color: rgba(255, 255, 255, 0.3);
}

table-container .heading {
  font-size: 1.25em;
  line-height: 1.3;
  margin-bottom: calc(var(--gap) * 2);
}
table-container .table {
  --cell-padding-y: 1rem;
  --cell-padding-x: 1.5rem;
  --cell-padding: var(--cell-padding-y) var(--cell-padding-x);
  table-layout: fixed;
  text-align: left;
}
table-container .table tr:nth-child(odd) {
  background-color: var(--theme-content-opposite);
}
table-container .table tr td:first-child() {
  border-right: 1px solid #969695;
}
table-container .table td {
  padding: var(--cell-padding);
}
table-container .table th {
  padding: var(--cell-padding);
  font-weight: 600;
  border-bottom: 1px solid lightgrey;
}
table-container .table.vertical tr td:first-child {
  font-weight: 600;
}
table-container .table.product-specs tr td:first-child {
  white-space: nowrap;
}
table-container .table.product-specs tr td:last-child {
  width: 100%;
}
table-container .table.related-products a {
  --font-colour: #DF3035;
}

main > .snippet a {
  --font-colour: var(--theme-accent) ;
}

navigation-bar {
  position: fixed;
  top: 0;
  width: 100%;
  width: var(--navigation-bar-width);
  height: var(--navigation-bar-height);
  max-height: -webkit-fill-available;
  padding: 0 calc(var(--gap) * 2) var(--gap-y);
  background: var(--theme-nav-background);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  overflow-y: auto;
  z-index: 1500;
  transition: background 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
navigation-bar a, navigation-bar button {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
navigation-bar global-search-mobile {
  display: none;
}
navigation-bar .navigation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
navigation-bar .navigation-container a.logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 95%;
  min-height: var(--logo-height);
  height: var(--logo-height);
  margin-bottom: var(--gap-y);
}
navigation-bar .navigation-container a.logo > svg {
  width: 100%;
  color: var(--theme-logo);
}
navigation-bar .navigation-container a.logo.logo-mobile {
  display: none;
}
navigation-bar .navigation-container global-search-desktop {
  margin-bottom: calc(var(--gap) * 2);
}
navigation-bar nav navigation-links {
  --fallback-max-height: 600px;
  --base-spacing: calc(var(--gap) * 0.5);
  display: block;
}
navigation-bar nav navigation-links .top-level-link {
  padding: calc(var(--gap) * 0.5) 0;
}
navigation-bar nav navigation-links .top-level-link:first-child {
  padding-top: 0;
}
navigation-bar nav navigation-links .top-level-link.has-children > a:hover svg {
  opacity: 1;
}
navigation-bar nav navigation-links .sub-menu li {
  padding: calc((var(--gap) * 0.5) / 2) 0;
}
navigation-bar nav navigation-links .sub-menu li:first-child {
  padding-top: calc(var(--gap) * 0.5);
}
navigation-bar nav navigation-links .sub-menu li:last-child {
  padding-bottom: calc(var(--gap) * 0.5);
}
navigation-bar nav navigation-links ul li a {
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), text-decoration-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
navigation-bar nav navigation-links ul li a:not(.active) {
  --font-colour: var(--theme-nav-font);
}
navigation-bar nav navigation-links ul li a.active {
  --font-colour: var(--theme-accent);
}
@media (hover: hover) {
  navigation-bar nav navigation-links ul li a.active:hover {
    text-decoration-color: rgba(255, 255, 255, 0) !important;
    text-decoration: none;
  }
}
navigation-bar nav navigation-links ul li a > svg {
  color: var(--theme-nav-font);
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  width: 1em;
  height: 1em;
  opacity: 0.2;
}
navigation-bar nav navigation-links ul li ul {
  --transition-duration: 0.325s;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  margin-left: var(--gap);
}
navigation-bar nav navigation-links ul li ul li a:not(.active) {
  opacity: 0.5;
}
@media (hover: hover) {
  navigation-bar nav navigation-links ul li ul li a:not(.active):hover {
    opacity: 1;
  }
}
navigation-bar nav navigation-links ul li.has-children > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
navigation-bar nav navigation-links ul li.has-children.open > a > svg {
  transform: rotate(90deg);
}
navigation-bar nav navigation-links ul li.has-children.open > ul {
  max-height: var(--link-max-height, var(--fallback-max-height));
  opacity: 1;
}
navigation-bar nav navigation-links .top-level-links > li > a {
  text-transform: uppercase;
}
navigation-bar nav navigation-links .mobile-link.hidden {
  display: none;
}
navigation-bar navigation-controls {
  display: none;
  align-items: center;
}
navigation-bar navigation-controls > *:not(:first-child) {
  margin-left: var(--gutter3);
}
navigation-bar navigation-controls a, navigation-bar navigation-controls button {
  display: block;
  height: fit-content;
}
navigation-bar navigation-controls a svg, navigation-bar navigation-controls button svg {
  height: 25px;
  width: 25px;
  color: var(--theme-nav-font);
  vertical-align: middle;
}
navigation-bar navigation-controls button.menu {
  --symbol-colour: var(--theme-nav-font);
}
navigation-bar navigation-controls button.menu svg {
  --corner-offset: 10px;
  width: 30px;
  height: 30px;
}
navigation-bar navigation-controls button.menu svg polygon {
  clip-path: polygon(140px 20px, 0 20px, var(--corner-offset, 10px) 0, 140px 0);
  transition: all var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
navigation-bar navigation-controls button.menu svg polygon:nth-child(1) {
  transform-origin: 15% 30%;
}
navigation-bar navigation-controls button.menu svg polygon:nth-child(2) {
  opacity: 1;
}
navigation-bar navigation-controls button.menu svg polygon:nth-child(3) {
  transform-origin: 15% 70%;
}
navigation-bar .extra-links {
  --font-colour: var(--theme-nav-font);
  --link-spacing: var(--gap);
  --opacity: 0.6;
  display: flex;
  font-size: 1.4rem;
  opacity: var(--opacity);
  margin-top: auto;
  flex-wrap: wrap;
  gap: 1rem;
}
navigation-bar .extra-links li:not(:last-of-type) {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 1ch;
  min-width: fit-content;
}
navigation-bar .extra-links a {
  text-decoration: none;
  white-space: nowrap;
}
@media (hover: hover) {
  navigation-bar .extra-links a:hover {
    text-decoration: underline;
  }
}

@media (max-width: 980px) {
  navigation-bar {
    --navigation-bar-width: 100%;
    --navigation-bar-height: min-content;
    --logo-height: 100%;
    --row-padding: 1rem var(--gap-x);
    --theme-nav-font: var(--theme-nav-font-mob);
    --theme-logo: var(--theme-nav-font-mob);
    position: relative;
    padding: 0;
    overflow: visible;
    border-right: none;
  }
  navigation-bar .extra-links {
    display: none;
  }
  navigation-bar global-search-desktop {
    display: none;
  }
  navigation-bar .navigation-container, navigation-bar global-search-mobile {
    background-color: var(--theme-nav-background-mob);
  }
  navigation-bar global-search-mobile {
    position: relative;
    display: block;
    padding: var(--row-padding);
    height: var(--navigation-mobile-search-height);
    margin-top: calc(-1 * var(--navigation-mobile-search-height) - 0px);
    transition: margin-top 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-bottom: 1px solid var(--theme-nav-spacing-colour);
    z-index: -1;
  }
  navigation-bar global-search-mobile form {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  navigation-bar global-search-mobile form input[type=search] {
    border-right: none;
  }
  navigation-bar global-search-mobile form select {
    --font-colour: #FFFFFF;
    --background-colour: #DF3035;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.4' height='10.1'%3E%3Cpath d='M16.7.7l-8 8-8-8' fill='none' stroke='%23FFFFFF' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");
  }
  navigation-bar navigation-controls {
    display: flex;
    margin-bottom: 0;
  }
  navigation-bar .navigation-container {
    flex-direction: row;
    justify-content: space-between;
    height: var(--navigation-container-height);
    padding: var(--row-padding);
    border-bottom: 1px solid var(--theme-nav-spacing-colour);
    z-index: 1500;
  }
  navigation-bar .navigation-container a.logo {
    margin-bottom: 0;
    width: fit-content;
  }
  navigation-bar .navigation-container a.logo.logo-desktop {
    display: none;
  }
  navigation-bar .navigation-container a.logo.logo-mobile {
    display: block;
    margin-left: -3rem;
  }
  navigation-bar .navigation-container a.logo svg {
    width: unset;
    height: 100%;
  }
  navigation-bar nav {
    position: absolute;
    top: calc(100% + 0px);
    left: 0;
    width: 100%;
    height: 100vh;
    padding: var(--gap-y) var(--gap-x) var(--navigation-container-height);
    background: var(--theme-nav-background-mob);
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  navigation-bar nav .top-level-links {
    opacity: 0;
  }

  body.mobile-search-open navigation-bar global-search-mobile {
    margin-top: 0;
  }
  body.mobile-navigation-open navigation-bar nav {
    pointer-events: all;
    opacity: 1;
  }
  body.mobile-navigation-open navigation-bar nav .top-level-links {
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  }
  body.mobile-navigation-open navigation-bar button.menu svg {
    --corner-offset: 0;
  }
  body.mobile-navigation-open navigation-bar button.menu svg polygon:nth-child(1) {
    transform: rotate(45deg) scaleY(80%);
    -webkit-transform: rotate(45deg) scaleY(80%);
  }
  body.mobile-navigation-open navigation-bar button.menu svg polygon:nth-child(2) {
    opacity: 0;
  }
  body.mobile-navigation-open navigation-bar button.menu svg polygon:nth-child(3) {
    transform: rotate(-45deg) scaleY(80%);
    -webkit-transform: rotate(-45deg) scaleY(80%);
  }
}
.heatsinks-home header.header,
.components-home header.header {
  transition: all 0.8s 0.2s;
}

.header {
  align-self: end;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.header .heading,
.header .subheading {
  text-transform: uppercase;
}
.header .heading {
  order: 2;
  font-weight: 800;
}
.header .subheading {
  color: var(--theme-accent);
  margin-bottom: var(--gap);
}
.header .subheading a {
  --heading-colour: var(--theme-accent);
  color: var(--theme-accent);
}
.header .snippet {
  order: 3;
  margin-top: 2rem;
}
@media (min-width: 981px) {
  .header .snippet {
    max-width: 1000px;
  }
}
.header.transition {
  opacity: 0;
  transform: translateY(-10%);
}

main {
  display: flex;
  flex-direction: column;
  grid-gap: var(--gap-y);
  z-index: 1000;
}
main content-blocks {
  display: grid;
  grid-gap: var(--gap-y);
}
main content-blocks section {
  display: grid;
  grid-gap: var(--gap-y);
}
body:not([data-components*=scroll-bar]) {
  position: relative;
}

@media (min-width: 1401px) {
  main {
    padding-bottom: var(--gap-y);
  }
}

side-bar {
  position: relative;
  width: var(--sidebar-width);
  max-width: var(--sidebar-max-width);
  height: 100%;
  z-index: 1000;
}
side-bar div.content-block.callToAction {
  position: fixed;
  bottom: 0;
  top: unset;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 980px) {
  side-bar div.content-block.callToAction {
    right: 0;
  }
}
@media (min-width: 980px) and (max-width: 1400px) {
  side-bar div.content-block.callToAction {
    margin-left: calc(clamp(2rem, 5vw, 8rem) * -1);
  }
}
@media (max-width: 1400px) {
  side-bar {
    margin-bottom: 200px;
  }
}
side-bar.free {
  --container-padding: 4rem;
}
side-bar.contain > div {
  padding-right: var(--gap-x);
}
side-bar[data-behaviour=sticky] {
  --sticky-spacing: 0;
}
side-bar[data-behaviour=sticky].contain {
  --sticky-spacing: var(--gap-y);
}
side-bar[data-behaviour=sticky] > div {
  position: sticky;
  top: var(--sticky-spacing);
}
@media (max-width: 1400px) {
  side-bar[data-behaviour=sticky] > div {
    position: relative;
    top: 0;
  }
}
@media (max-width: 1400px) {
  side-bar:not([data-behaviour=foldout]) {
    --sidebar-max-width: 100%;
    --sidebar-width: 100%;
  }
  side-bar:not([data-behaviour=foldout]) > div {
    margin-bottom: calc(var(--gap-y) * 2);
  }
  side-bar.contain > div {
    padding: 0;
  }
}
side-bar[data-behaviour=foldout] {
  z-index: 1750;
  transition: max-width 0.7s cubic-bezier(0.42, 0, 0.58, 1), transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1), top 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
side-bar[data-behaviour=foldout] > div {
  position: sticky;
  top: 0;
}
@media (max-width: 1400px) {
  side-bar[data-behaviour=foldout] {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: min(100vw, 400px);
    max-width: min(100vw, 400px);
  }
  side-bar[data-behaviour=foldout].active {
    transform: translateX(0%);
  }
  side-bar[data-behaviour=foldout] > div {
    position: relative;
  }
}
@media (max-width: 980px) {
  side-bar[data-behaviour=foldout] .open-trigger {
    display: none !important;
  }
  side-bar[data-behaviour=foldout] .close-trigger {
    display: block;
  }
}

page-background {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: scale(1);
  background: var(--theme-page-background);
  z-index: 500;
}
page-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--theme-content-background);
  z-index: 501;
}
@media (min-width: 981px) {
  page-background::after {
    top: 0;
    left: calc(var(--navigation-bar-width));
    width: calc(100% - var(--navigation-bar-width));
    height: 100%;
  }
}
page-background video {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
  user-select: none;
  opacity: 0.3;
}
page-background img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
  user-select: none;
}
page-background img.logo {
  opacity: 0.03;
}
page-background img::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 40%, #12152B 100%);
  z-index: 500 -1;
}

body:not(#home) .content-wrapper header {
  padding-top: 200px;
  margin-bottom: var(--gap-y);
}
body:not(#home) .content-wrapper header:not(.has-subheading) {
  margin-top: calc(-1 * var(--gap-y));
}
body:not(#home) .content-wrapper header.has-subheading {
  margin-top: calc(-1.85 * var(--gap-y));
}
@media (max-width: 980px) {
  body:not(#home) .content-wrapper header {
    padding-top: var(--gap-y);
    margin-top: calc(-1 * var(--gap-y)) !important;
  }
}

body#home.components-home .content-wrapper header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--gap);
  margin-bottom: var(--gap-y);
}
body#home.components-home .content-wrapper header > h1, body#home.components-home .content-wrapper header p {
  grid-column: 1/3;
}
body#home.components-home .content-wrapper header h1 {
  grid-row: 1;
}
@media (max-width: 400px) {
  body#home.components-home .content-wrapper header h1 {
    font-size: 24px;
  }
}
body#home.components-home .content-wrapper header p {
  grid-column: 1/-1;
  grid-row: 2;
}
body#home.components-home .content-wrapper header img {
  grid-column: 3;
  width: 100%;
  height: auto;
}
body#home.components-home .content-wrapper header + main {
  margin-top: 0;
}
@media (min-width: 768px) {
  body#home.components-home .content-wrapper header {
    grid-gap: 0;
    grid-template-columns: repeat(auto-fill, min-max(200px, 25%));
  }
  body#home.components-home .content-wrapper header p {
    margin-top: 0;
  }
  body#home.components-home .content-wrapper header img {
    max-width: 150px;
    margin-left: auto;
  }
}
body#home.components-home .content-wrapper .about-us-block {
  background: linear-gradient(120deg, #10124d 20%, #131f6b 80%);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: relative;
}
body#home.components-home .content-wrapper .about-us-block > div:last-of-type {
  padding-bottom: 75px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
}
body#home.components-home .content-wrapper .about-us-block > div:first-of-type {
  display: flex;
  padding: calc(var(--gap) * 2);
  background-color: #DF3035;
  gap: 16px;
  justify-content: center;
  flex-direction: column;
}
body#home.components-home .content-wrapper .about-us-block > div:last-of-type {
  flex-grow: 1;
  grid-gap: var(--gap);
  padding-top: var(--gap);
}
body#home.components-home .content-wrapper .about-us-block > div:last-of-type > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: var(--gap);
}
body#home.components-home .content-wrapper .about-us-block > div:last-of-type > div svg {
  width: 60%;
  color: white;
  max-width: 80px;
  margin-bottom: var(--gap);
}
body#home.components-home .content-wrapper .about-us-block > div:last-of-type > div p {
  text-align: center;
  text-transform: uppercase;
  color: #DF3035;
  font-size: 1.4rem;
}
body#home.components-home .content-wrapper .about-us-block > a {
  position: absolute;
  right: 0;
  bottom: 0;
}
body#home.components-home .content-wrapper .content-block.callToAction {
  background: linear-gradient(120deg, #10124d 0%, #131f6b 80%);
  padding: calc(var(--gap) * 2) 0 0 calc(var(--gap) * 2);
  display: flex;
  flex-direction: column;
}
body#home.components-home .content-wrapper .content-block.callToAction .call-to-action-heading, body#home.components-home .content-wrapper .content-block.callToAction .call-to-action-subheading {
  text-transform: uppercase;
}
body#home.components-home .content-wrapper .content-block.callToAction .call-to-action-heading {
  --heading-colour: #FFFFFF;
  font-size: 1.25em;
  line-height: 1.3;
  padding-right: calc(var(--gap) * 2);
  font-weight: 800;
  margin-bottom: calc(var(--gap) * 2);
}
body#home.components-home .content-wrapper .content-block.callToAction .call-to-action-subheading {
  --heading-colour: #DF3035;
  font-size: 0.9375em;
  line-height: 1.6;
  margin-bottom: var(--gap);
}
body#home.components-home .content-wrapper .content-block.callToAction .call-to-action-button {
  margin-top: var(--gap);
  margin-left: calc(var(--gap) * 3);
  align-self: flex-end;
  width: fit-content;
}
body#home.components-home .content-wrapper scroll-bar {
  max-width: unset !important;
}
@media (min-width: 768px) {
  body#home.components-home .content-wrapper .about-us-block {
    padding-bottom: unset;
    flex-direction: row;
  }
  body#home.components-home .content-wrapper .about-us-block > div:first-of-type {
    max-width: 35%;
    padding-right: var(--spacer2);
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 75% 100%);
  }
  body#home.components-home .content-wrapper .about-us-block > div:last-of-type {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    padding: var(--spacer1);
    margin-left: -5%;
  }
}
@media (min-width: 981px) and (max-width: 1399px) {
  body#home.components-home .content-wrapper .about-us-block {
    flex-direction: column;
  }
  body#home.components-home .content-wrapper .about-us-block > div:first-of-type {
    max-width: unset;
    clip-path: unset;
  }
  body#home.components-home .content-wrapper .about-us-block > div:last-of-type {
    padding: var(--spacer1);
    margin-left: 0%;
  }
}
@media (min-width: 1201px) {
  body#home.components-home .content-wrapper header, body#home.components-home .content-wrapper scroll-bar, body#home.components-home .content-wrapper .about-us-block, body#home.components-home .content-wrapper .content-block.callToAction {
    max-width: 1200px !important;
  }
}

content-blocks [data-columns] {
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  grid-gap: calc(var(--gap) * 2);
}
@media (min-width: 769px) {
  content-blocks [data-columns="2"] {
    --columns: 2;
  }
}
@media (min-width: 980px) {
  content-blocks [data-columns="2"] {
    --columns: 1;
  }
}
@media (min-width: 1201px) {
  content-blocks [data-columns="2"] {
    --columns: 2;
  }
}
@media (min-width: 1401px) {
  content-blocks [data-columns="2"] {
    --columns: 1;
  }
}
@media (min-width: 1800px) {
  content-blocks [data-columns="2"] {
    --columns: 2;
  }
}

search-element .search-input svg {
  height: 32px;
}

modal-wrapper {
  --wrapper-background-colour: rgba(255, 255, 255, 0.95);
  --modal-close-colour: $black;
  --container-background-colour: white;
  --min-width:300px;
  --max-width:1120px;
  --width:clamp(var(--min-width), 90vw, fit-content);
  --min-height:600px;
  --max-height:calc(100vh - var(--gutter2));
  --height:clamp(var(--min-height), 90vw, fit-content);
  --close-button-size: 30px;
  --nav-button-size: 40px;
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--wrapper-background-colour);
  z-index: 10000;
  display: flex;
  place-items: center;
  place-content: center;
  transition: opacity 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  overscroll-behavior: contain;
}
@supports (--v:-webkit-fill-available ) {
  modal-wrapper {
    --max-height:-webkit-fill-available;
  }
}
modal-wrapper.loading {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1) 0.3s;
}
modal-wrapper.loading modal-container {
  opacity: 0;
  transform: translateY(2vh);
  transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1), transform 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
modal-wrapper button.close {
  position: absolute;
  top: var(--gutter1);
  right: var(--gutter1);
  width: var(--close-button-size);
  height: var(--close-button-size);
}
modal-wrapper button.close svg {
  color: var(--modal-close-colour, #DF3035);
}
modal-wrapper button.close svg circle, modal-wrapper button.close svg path {
  transition: 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}
@media (hover: hover) {
  modal-wrapper button.close:hover svg {
    --fill:white;
    --stroke:var(--secondary, #DF3035);
  }
}
modal-wrapper button.navigation {
  position: absolute;
  width: var(--nav-button-size);
  height: var(--nav-button-size);
  top: calc(50% - var(--nav-button-size) / 2);
  background-color: var(--secondary, #DF3035);
  border-radius: 50%;
  border: 2px solid var(--secondary, #DF3035);
  color: white;
  display: flex;
  place-content: center;
  place-items: center;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
modal-wrapper button.navigation.previous {
  left: var(--gutter1);
}
modal-wrapper button.navigation.next {
  right: var(--gutter1);
}
modal-wrapper button.navigation svg {
  width: 50%;
  height: 50%;
}
@media (hover: hover) {
  modal-wrapper button.navigation:hover {
    transform: scale(1.1);
    color: var(--secondary, #DF3035);
    background-color: white;
  }
}

modal-container {
  transform: translateY(0);
  transition: opacity 0.5s cubic-bezier(0.42, 0, 0.58, 1) 0.3s, transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  width: var(--width);
  height: var(--height);
  max-height: var(--max-height);
  max-width: var(--max-width);
  border: 1px solid #dcdcdc;
  border-radius: var(--border-radius, 6px);
  background-color: var(--container-background-colour);
  position: relative;
  display: flex;
  flex-direction: column;
  margin: var(--gutter1);
  overscroll-behavior: contain;
}
@supports not (width: clamp(1px, 5vw, 10px)) {
  modal-container {
    --width: 60vw;
    max-width: var(--max-width);
    min-width: var(--min-width);
    --height: 80vw;
    max-height: var(--max-height);
    min-height: var(--min-height);
  }
}
modal-container:empty {
  width: calc(var(--max-width) / 2);
  height: calc(var(--max-height) / 2);
}
modal-container:empty:after {
  --size: 20px;
  content: "";
  position: absolute;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  opacity: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 2px solid var(--loader-colour, #969695);
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: loader-rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  animation-play-state: paused;
  opacity: 1;
  animation-play-state: running;
}
@keyframes loader-rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
modal-container > button.close {
  position: relative;
  order: -1;
  align-self: flex-end;
  top: initial;
  right: initial;
  margin-top: calc(-1 * var(--close-button-size) / 2);
  margin-bottom: calc(-1 * var(--close-button-size) / 2);
  margin-right: calc(-1 * var(--close-button-size) / 2);
}

modal-content {
  display: block;
  position: sticky;
  overscroll-behavior: contain;
  width: inherit;
  height: inherit;
  max-height: inherit;
  max-width: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--gutter1);
}
modal-content > * {
  max-width: 100%;
}

modal-wrapper[data-type=image] .image {
  max-height: calc(var(--max-height) - var(--gutter6));
  border: none;
}
modal-wrapper[data-type=image] .image img {
  max-height: calc(100vh - var(--gutter3) - var(--gutter4));
  width: auto;
  height: auto;
  margin: auto;
}

modal-wrapper[data-type=default] modal-content > img[src*=".svg"] {
  width: 100%;
  height: auto;
}

modal-wrapper[data-type=video] {
  --wrapper-background-colour: rgba(0, 0, 0, 0.95);
  --modal-close-colour: #FFFFFF;
}
modal-wrapper[data-type=video] modal-container {
  --aspect-ratio: 16/9;
  aspect-ratio: var(--aspect-ratio);
  display: block;
  position: relative;
  width: 100%;
  background-color: black;
  background-color: white;
  padding: var(--gap);
}
@supports not (aspect-ratio: 16/9) {
  modal-wrapper[data-type=video] modal-container {
    aspect-ratio: initial;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    height: 0;
  }
  modal-wrapper[data-type=video] modal-container > * {
    position: absolute;
  }
}
modal-wrapper[data-type=video] modal-container iframe, modal-wrapper[data-type=video] modal-container canvas {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
}
modal-wrapper[data-type=video] modal-container modal-content {
  height: 100%;
  border-radius: var(--border-radius, 6px);
}

.open-in-modal {
  cursor: pointer;
  transition: opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
.open-in-modal:after {
  --size: 20px;
  content: "";
  position: absolute;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  opacity: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: white solid var(--loader-colour, #969695);
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: loader-rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  animation-play-state: paused;
}
@keyframes loader-rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.open-in-modal.loading {
  pointer-events: none;
}
.open-in-modal.loading:after {
  opacity: 1;
  animation-play-state: running;
}

img.open-in-modal, .open-in-modal img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.back-to-top {
  --hide-offset: -10px;
  --svg-width: 18px;
  --edge-distance: 15px;
  --right-offset: 0px;
  display: block;
  position: fixed;
  right: calc(var(--right-offset) + var(--edge-distance));
  bottom: calc(var(--edge-distance) + var(--hide-offset));
  width: calc(var(--svg-width) + 12px);
  height: calc(var(--svg-width) + 12px);
  background: #131f6b;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  z-index: 1001;
  pointer-events: all;
  transition: opacity 0.2s var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), transform 0.2s var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  display: none;
}
.back-to-top svg {
  position: relative;
  top: -2px;
  width: var(--svg-width);
  height: var(--svg-width);
  color: #FFFFFF;
}
.back-to-top:not(.hide) {
  pointer-events: all;
  opacity: 1;
  cursor: pointer;
  transform: translateY(var(--hide-offset));
}

@media (min-width: 1401px) {
  side-bar ~ .back-to-top {
    --right-offset: var(--sidebar-width);
  }
}