////////////////////////////////////////////////////////////////////////////////
// Transitions
////////////////////////////////////////////////////////////////////////////////

@use "helpers/ease";

$duration : 0.2s;
$ease     : ease.$in-out;
$delay    : null;

$transition : var(--transition-duration, $duration) var(--transition-ease, $ease) var(--transition-delay, $delay);

$sidebar-open: 0.7s ease.$out-quart;
$search-open: 0.4s ease.$out-cubic;
$nav-background: 0.3s ease.$out-quart;