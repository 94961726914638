@use '../_variables' as *;
@use 'collections/font/crop' as font;
@use 'modules/colour/encode' as colour;


@mixin tick-marker($fill, $stroke : white) {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='"+colour.encode($fill)+"' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='"+colour.encode($stroke)+"' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
}

@mixin style($fill, $stroke : white) {
  > li::before { 
    content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='"+colour.encode($fill)+"' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='"+colour.encode($stroke)+"' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
  }
}

// Unorderd Lists --------------------------------------------------------------

@mixin ul($list-style-colour : $green, $list-style-accent-colour : white) {

  @include style($list-style-colour, $list-style-accent-colour);

  > li { 

    position      : relative; 
    padding-left  : calc(var(--list-style-size, 1em) * 1.8);

    & + li { margin-top : var(--line-style-spacing, #{$gap0}); }
    
    &::before { 
      display  : inline-block; 
      top      : 0.1em; 
      left     : 0; 
      position : absolute;
      width    : var(--list-style-size, 1em); 
      height   : var(--list-style-size, 1em); 
    }
  }

  @content;

}

// Ordered Lists ---------------------------------------------------------------

@mixin ol() {

  counter-reset : counter;

  > li { 
    position     : relative; 
    display      : block; 
    padding-left : 22px;
    margin-left  : var(--list-style-offset, 0);

    & + li { margin-top:$gap0; }

    &::before { 
      content           : counter(counter)'.';
      counter-increment : counter;
      color             : var(--list-style-colour, var(--font-colour)); 
      position          : relative; 
      margin-right      : 0.5em;
    }
  }

  @content;
  
}
