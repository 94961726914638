@use 'sass:map';
@use 'collections/styling/aspect-ratio' as *;

@mixin container($ratio:'16/9') {
	
	@include aspect-ratio($ratio);

	display          : block;
	position         : relative;
	width            : 100%;
	background-color : black;

	iframe, canvas {
		position : absolute;
		display  : block;
		top      : 0; 
		left     : 0;
		height   : 100% !important; 
		width    : 100% !important;
	}
}