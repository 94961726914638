@use '_modules' as *;

// TODO: REMOVE UNECESSARY CODE

////////////////////////////////////////////////////////////////////////////////
// Layout
////////////////////////////////////////////////////////////////////////////////

// Layout styling should be used to adjust layout properties only.
// Where possible, it should not be used to manage the flow of a page.
// Instead only widths, height, display, grid, position, padding, margins, order, and alike
// should be the properties defined in these files. Keep the layouts here and
// component styling in their respective files.

html {
  background-color: black;
}

body {
  $sidebar-width: 30vw;

  --navigation-bar-width: clamp(250px, 25vw, 300px);
  --navigation-bar-height: 100vh;

  --sidebar-width: #{$sidebar-width};
  --sidebar-max-width: var(--sidebar-width);

  --logo-height: 200px;

  --gap-x: clamp(2rem, 5vw, 8rem);
  --gap-y: 50px;

  --row-content: 1 / -1;
  --row-header: calc(1 + var(--content-offset, 0));
  --row-main: calc(2 + var(--content-offset, 0));

  &[data-sidebar='top'] {
    --row-sidebar: 1 / -1;
  }
  &[data-sidebar='header'] {
    --row-sidebar: var(--row-header) / -2;
  }
  &[data-sidebar='content'] {
    --row-sidebar: var(--row-main) / -2;
  }

  --column-navigation: 1;
  --column-content: 2;
  --column-header: 2;
  --column-main: 2;
  --column-sidebar: 3;

  --rows: var(--logo-height) auto 1fr;
  --columns: var(--navigation-bar-width) minmax(0, 1fr) auto;

  display: grid;
  grid-gap: var(--gap-y) var(--gap-x);
  grid-template-rows: var(--rows);
  grid-template-columns: var(--columns);

  navigation-bar {
    grid-row: var(--row-navigation);
    grid-column: var(--column-navigation);
  }

  .content-wrapper {
    grid-row: var(--row-content);
    grid-column: var(--column-content);
    z-index: $z-index-main;
  }

  side-bar {
    grid-row: var(--row-sidebar);
    grid-column: var(--column-sidebar);
  }

  &.heatsinks-home,
  &.components-home {
    --row-content: 2;
  }

  @include break-in('large') {
    &[data-sidebar='top'],
    &[data-sidebar='header'],
    &[data-sidebar='content'] {
      --column-sidebar: 2;
      --row-content: 1 / -2;

      side-bar {
        --row-sidebar: calc(var(--row-content) + 3);
      }
    }
  }

  @include break-in('small-medium') {
    place-content: start;

    --navigation-container-height: 60px;
    --navigation-mobile-search-height: 61px;

    --rows: auto auto 1fr auto;
    --columns: 0 minmax(0, 1fr) 0;

    --row-navigation: 1;
    --column-navigation: 1 / -1;

    &:not(.heatsinks-home, .components-home) {
      --row-content: 2 / span 3 !important;
    }

    // --column-content: 1/ -1;

    // --row-content: 2;

    // --content-offset: 1;

    & > * {
      width: 100%;
    }

    main {
      margin-top: -2rem;
    }
  }

  &.mobile-open,
  &.modal-open {
    overflow: hidden;
  }
}
