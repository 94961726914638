////////////////////////////////////////////////////////////////////////////////
// Custom Properties
////////////////////////////////////////////////////////////////////////////////

@use '_modules' as *;
@use 'sass:math';

:root {

  // Colours -------------------------------------------------------------------

  --primary         : #{$white}; 
  --secondary       : #{$blue};

  body {

    &[data-theme="default"] {
      --theme-page-background    : #{$theme-default-page-background};
      --theme-content-background : #{$theme-default-content-background};
      --theme-content-opposite   : #{$white};
      --theme-nav-background     : #{$theme-default-nav-background};
      --theme-nav-background-mob : #{$theme-default-nav-background-mob};
      --theme-nav-font           : #{$theme-default-nav-font};
      --theme-nav-font-mob       : #{$theme-default-nav-font-mob};
      --theme-nav-spacing-colour : #{$theme-default-nav-spacing-colour};
      --theme-accent             : #{$theme-default-accent};
      --theme-logo               : #{$theme-default-logo};
      --font-colour              : #{$theme-default-font};
      --heading-colour           : #{$theme-default-heading};
    }

    &[data-theme="white"] {
      --theme-page-background    : #{$theme-white-page-background};
      --theme-content-background : #{$theme-white-content-background};
      --theme-content-opposite   : #{$white};
      --theme-nav-background     : #{$theme-white-nav-background};
      --theme-nav-background-mob : #{$theme-white-nav-background-mob};
      --theme-nav-font           : #{$theme-white-nav-font};
      --theme-nav-font-mob       : #{$theme-white-nav-font-mob};
      --theme-nav-spacing-colour : #{$theme-white-nav-spacing-colour};
      --theme-accent             : #{$theme-white-accent};
      --theme-logo               : #{$theme-white-logo};
      --font-colour              : #{$theme-white-font};
      --heading-colour           : #{$theme-white-heading};
    }

    &[data-theme="black"] {
      --theme-page-background    : #{$theme-black-page-background};
      --theme-content-background : #{$theme-black-content-background};
      --theme-content-opposite   : #{$white};
      --theme-nav-background     : #{$theme-black-nav-background};
      --theme-nav-background-mob : #{$theme-black-nav-background-mob};
      --theme-nav-font           : #{$theme-black-nav-font};
      --theme-nav-font-mob       : #{$theme-black-nav-font-mob};
      --theme-nav-spacing-colour : #{$theme-black-nav-spacing-colour};
      --theme-font               : #{$theme-black-font};
      --theme-accent             : #{$theme-black-accent};
      --theme-logo               : #{$theme-black-logo};
      --font-colour              : #{$theme-black-font};
      --heading-colour           : #{$theme-black-heading};
    }
  }


  // Breakpoints ---------------------------------------------------------------
  
  @each $name, $value in $breakpoints {
    --breakpoint-#{$name} : #{$value};
  }

  // Fonts ---------------------------------------------------------------------
  
  --font-colour        : #{var(--theme-font, $font-colour)};
  --heading-colour     : #{var(--theme-heading, $white)};
  --sub-heading-colour : #{var(--secondary, $blue)};

  // Transitions ---------------------------------------------------------------

  --transition-duration : #{$transition-duration};
  --transition-ease     : #{$transition-ease};
  --transition-delay    : #{$transition-delay};

  // Units ---------------------------------------------------------------------

  --gap    : #{$base-gap};
  --gutter : #{$base-gutter};
  --spacer : #{$base-spacer};
 
  /// Set the gutters and spacers with clamp 
  /// @see https://developer.mozilla.org/en-US/docs/Web/CSS/clamp
  @for $i from 0 through 6 {

    --gutter#{$i}  : #{gutter($i)}; 
    --gutter#{$i}- : #{gutter($i, $use-negative : true)};

    $spacer-growth : if($i == 0, 1, $i * 3);
    --spacer#{$i}  : #{spacer($spacer-growth)}; 
    --spacer#{$i}- : #{spacer($spacer-growth, $use-negative : true)};
  }
  
  /// Sets the gutters and spacers using the calc 
  /// method for browsers that don't support clamp
  @supports not ( width:clamp(1px, 5vw, 10px)) {
    @for $i from 0 through 6 {

      --gutter#{$i}  : #{gutter($i, $use-clamp : false )}; 
      --gutter#{$i}- : #{gutter($i, $use-clamp : false, $use-negative : true )};

      $spacer-growth : if($i == 0, 2, $i * 4);
      --spacer#{$i}  : #{spacer($spacer-growth, $use-clamp : false )}; 
      --spacer#{$i}- : #{spacer($spacer-growth, $use-clamp : false, $use-negative : true )};
    }    
  }
}