@use 'modules' as *;

content-blocks {

  // ... Column layout

  $column-config: (
    '2' : (
      768 : 2,
      979: 1,
      1200: 2,
      1400: 1, // ... TODO: check this doesn't ruin pages without sidebar components
      1799: 2
    )
  );

  [data-columns] {
    grid-template-columns: repeat(var(--columns, 1), 1fr);
    grid-gap: $gap2;
  }

  @each $column-number, $map in $column-config {
    [data-columns="#{$column-number}"] {

      @each $breakpoint, $breakpoint-columns in $map {

        @include break-out($breakpoint) {
          --columns : #{$breakpoint-columns};
        }

      }

    }
  }
}