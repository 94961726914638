@use '_modules' as *;

body:not(#home) {
  .content-wrapper {
    header {
      padding-top: 200px;
      margin-bottom: var(--gap-y);
      
      &:not(.has-subheading) {
        margin-top: calc(-1 * var(--gap-y));
      }

      &.has-subheading {
        margin-top: calc(-1.85 * var(--gap-y));
      }

      @include break-in(small-medium) {
        padding-top: var(--gap-y);
        margin-top: calc(-1 * var(--gap-y)) !important;
      }
    }
  }
}

body#home.components-home{
  .content-wrapper{
    header{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: $gap;
      margin-bottom: var(--gap-y);

      > h1, p{
        grid-column: 1 / 3;
       
      }

      h1{
        grid-row: 1;
        @media(max-width: 400px){
          font-size: 24px;
        }
      }

      p{
        grid-column: 1 / -1;
        grid-row: 2
      }
      img{
          grid-column: 3;
          width: 100%;
          height: auto;
      }
      & + main{
        margin-top: 0;
      }
      @media(min-width: 768px){
        grid-gap: 0;
        grid-template-columns: repeat(auto-fill, min-max(200px ,25%));

        p{margin-top: 0;}

        img{
          max-width: 150px;
          margin-left: auto;
        }
      }
    }

    .about-us-block{
      background: linear-gradient(120deg, #10124d 20%, #131f6b 80%);
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      position: relative;

      > div:last-of-type{
        padding-bottom: 75px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
      }

      > div{
        &:first-of-type{
          display: flex;
          padding: $gap2;
          background-color: $red;
          gap: 16px;
          justify-content: center;
          flex-direction: column;
        }
        &:last-of-type{
          flex-grow: 1;
          grid-gap: $gap;
          padding-top: $gap;
          > div{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: $gap;
            svg{
              width: 60%;
              color: white;
              max-width: 80px;
              margin-bottom: $gap;
            }
            p{
              text-align: center;
              text-transform: uppercase;
              color: $red;
              font-size: $font-size-min;
            };
          }
        }
      }
      > a {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .content-block.callToAction {
      background: $reverse-gradient;
      padding: $gap2 0 0 $gap2;
    
      display: flex;
      flex-direction: column;
      
      .call-to-action-heading, .call-to-action-subheading {
        text-transform: uppercase;
      }
    
      .call-to-action-heading {
        --heading-colour: #{$white};
        @include heading-h2;
        // font-size: $font-size-h3;
        padding-right: $gap2;
        font-weight: 800;
        margin-bottom: $gap2;
      }
      .call-to-action-subheading {
        --heading-colour: #{$red};
        @include heading-h3;
        margin-bottom: $gap;
        // font-size: $font-size-h4;
      }
      .call-to-action-button {
        margin-top: $gap;
        margin-left: $gap3;
        align-self: flex-end;
        width: fit-content;
      }
    }

    scroll-bar{
      max-width: unset !important;
    }
    @media (min-width:768px) {

      .about-us-block{
        padding-bottom: unset;;
        flex-direction: row;
        > div{
          &:first-of-type{
            max-width: 35%;
            padding-right: $spacer2;
            clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 75% 100%);
          }
          &:last-of-type{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
            padding: $spacer;
            margin-left: -5%;
          }
        }
      }
    }

    @media (min-width: 981px) and (max-width: 1399px) {
      .about-us-block {
        flex-direction: column;
        > div {
          &:first-of-type{
            max-width: unset;
            clip-path: unset;
          }
          &:last-of-type{
            padding: $spacer;
            margin-left: 0%;
          }

        }
      }
    }

    @media (min-width:1201px) {
      header, scroll-bar, .about-us-block, .content-block.callToAction {
        max-width: 1200px !important;
      }
    }
  }
}
