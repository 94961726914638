@use '_modules' as *;
@use 'modules/colour/encode' as colour;

// Globally style inputs and submit handles

@include form-character-inputs() {
  @include form-character-input();
  &:not(select) {
    width: 100%;
  }
}

input[type="search"] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display:none;
    -webkit-appearance:none;
  }
}

@include form-all-inputs() {
  &:focus { @include form-input-focus() }
}

input[type="submit"], button[type="submit"] {
  @include button-default();
}


select {  
  text-transform: uppercase;
  padding-right: calc($form-base-input-padding * 3);
  color: var(--font-colour);
  @include form-arrow-down-image($font-colour);
  background-repeat:no-repeat;
  background-size: 1em;
  background-position:calc(100% - #{$form-base-input-padding}) center;
  width: min-content;

  option {
    text-transform: uppercase;
    font-weight: 400;
  }
}

.input-container {
  &.horizontal { 
    grid-auto-flow: column !important;
    align-items: center;
    justify-content: space-between;
  }

  &:not(.radio, .checkbox) {
    @include form-text-container();

    input[maxlength], input[size] {
      width: fit-content;
    }
  }

  &.radio, &.checkbox { @include form-radio-checkbox-container() }
  &.radio { @include form-radio-container() }
}

.multi-input {
  @include form-multi-input();
}

radio-tabs {
  @include form-radio-tabs();
}


form {

  // Make submit button disabled when form is invalid.

  [type="submit"] {
    transition: opacity $transition;
  }
  
  &:invalid {
    [type="submit"] { 
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

}



// ============================================================================
// FREEFORM STYLING
// ============================================================================

form {
  &[data-freeform] {

    h3, h4, h5, h6 { @include heading-h2() }

    .ff-form-success {
      --font-colour: #{$green};
      margin-bottom: $gap2;

      p {
        display: flex;
        align-items: center;

        &::before {
          @include list-tick-marker($green);
          display: block;
          margin-right: $gap;
          width: 1.25em;
          height: 1.25em;
        }
      }
    }

    .ff-form-errors {
      --font-colour: #{$red};
      margin-bottom: $gap2;
    }

    .ff-errors {
      --font-colour: #{$red};
      margin-top: $gap;
      color: var(--font-colour);
    }

    .form-fields {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: $gap;

      .freeform-row {
        display: grid;
        grid-gap: 2rem;
        grid-auto-flow: column;
        height: fit-content;

        .freeform-column {
          position: relative;

          h3, h4, h5, h6 {
            margin-top: $gap2;
          }

          &.freeform-column-content-align-right {
            text-align: right;
          }

          label:not(.freeform-input-only-label) {
            opacity: 0.7;
            & + .freeform-input {
              margin-top: calc($gap / 2);
            }
          }
        }
      }

      @include form-base-required();
    }
  }


  // INDIVIDUAL FORM STYLES

  &[data-handle="contact"] {
    --submit-height: 41px;

    .form-fields {

      label:not(.freeform-input-only-label) {
        text-transform: uppercase;
      }

      .message, .submit-row {
        grid-row: 4;
        grid-column: 1;
      }
  
      .message { 
        .freeform-column {
          display: grid;
        }
      }

      textarea[name="message"] {
        z-index: 1;
        padding-bottom: calc(41px + $gap);
      }
  
      .submit-row {
        width: 100%;
        z-index: 1;
        margin: auto 0 0 auto;

        button {
          margin-left: auto;
          z-index: 2;
        }
      }
    }
  }

  &[data-handle="creditApplication"] {
    .freeform-row {
      &:nth-last-of-type(-n+2), &:nth-last-of-type(-n+1) {
        margin-top: 2rem;
      }

      &:last-of-type {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
  }
}