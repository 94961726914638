/// Global typographic styling that effects everything. 
/// If you want to update styling for content block text fields, introduction fields, 
/// or anything assigned a .text class on it's container then edit this file:
/// @see /src/sass/components/text

@use '_modules' as *;


body {
  @include font-montserrat();
	color       : var(--font-colour, $font-colour);
  line-height : $font-line-height;
  font-size   : $font-size;
  @include responsive-fontsize($font-size-min, $font-size-max);
}

h1 { @include heading-h1; }
h2 { @include heading-h2; }
h3 { @include heading-h3; }
h4 { @include heading-h4; }
h5 { @include heading-h5; }
h6 { @include heading-h6; }

h1, h2, h3, h4, h5, h6 { 

  @include font-montserrat(bold);
  color:var(--heading-colour, var(--font-colour));
  
  &.sub-heading {
    color:var(--sub-heading-colour); 
    @include font-lato(semi-bold);
  }

  span, strong, p {
    color       : inherit;
    font-family : inherit;
    font-weight : inherit;
  }
}

// q, blockquote {
//   quotes: "“" "”" "‘" "’";
//   &::before { content: open-quote; }
//   &::after { content: close-quote; }
// }

a { 
  cursor: pointer;
  transition: text-decoration-color $transition, color $transition;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: $transparent;
  &.external {
    display:inline-flex; 
    align-items: center;
    svg.external-link {
      opacity:0.6; 
      width:0.8em;
      height:0.8em;
      margin-left:0.5em; 
    }
  }
  &:hover {
    text-decoration-color: var(--font-colour);
  }
}

@include heading-all() {
  a {
    @include hover() {
      text-decoration-color: var(--heading-colour);
    }
  }
}



p, a {
  color: var(--font-colour);
}

ol, ul {
  list-style: none;
  list-style-position: inside;
}

li { 
	a { text-underline-offset:2px; }
}

small {
  font-size:font-size(10);
  line-height:1.8;
}

hr { 
  width: 100%;
  background-color: var(--hr-colour, $grey5);
  margin-top:$gap2; 
  margin-bottom:$gap2; 
}