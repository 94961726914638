////////////////////////////////////////////////////////////////////////////////
/// Font
////////////////////////////////////////////////////////////////////////////////

@use 'colours' as *;

$colour      : $grey2;
$size        : 1.5rem !default;
$size-min    : 1.4rem !default; 
$size-max    : 1.6rem !default; 
$line-height : 1.6 !default; 

$size-h1     : 5em !default;
$size-h1-min : 4em !default;
$size-h2     : 2em !default;
$size-h3     : 1.5em !default;
$size-h4     : 1.3em !default;
$size-h5     : 1.3em !default;
$size-h6     : 1.3em !default;
  
/// ============================================================================
/// Lato Font
/// ============================================================================
/// This font it primarily used for headings (<h*> tags)
/// @example @include font-lato(bold)

@mixin lato($weight:regular) {

  font-family:lato, sans-serif;
  font-style: normal;

  @if ( $weight == 'regular') {
    font-weight: 400;
  } @else if ( $weight == 'semi-bold' or $weight == 'semibold') {
    font-weight: 600;
  } @else if ( $weight == 'bold') {
    font-weight: 700;
  }

  font-display: swap;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/// ============================================================================
/// Montserrat Font
/// ============================================================================
/// This is the base font used for all standard copy throughout the site.
/// @example @include font-montserrat()

@mixin montserrat($weight:regular) {

  font-family:montserrat, sans-serif;
  
  @if ( $weight == 'regular') {
    font-weight: 500;
    font-style: normal;
  } @else if ( $weight == 'italic') {
    font-weight: 500;
    font-style: italic;
  } @else if ( $weight == 'bold') {
    font-weight: 600;
    font-style: normal;
  }

  font-display: swap;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}