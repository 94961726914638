@use '_modules' as *;

main {
	display: flex;
	flex-direction: column;
	grid-gap: var(--gap-y);
	z-index: $z-index-main;
	// height: 100%;

	content-blocks {
		display: grid;
		grid-gap: var(--gap-y);

		section {
			display: grid;
			grid-gap: var(--gap-y);
		}
	}

	@include component-doesnt-exist('scroll-bar') {
		position: relative;
	}

	@include break-out('large') {
		padding-bottom: var(--gap-y);
	}
}