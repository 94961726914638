@use '../_variables' as *;
@use '../_helpers/misc.scss' as *;
@use "styling" as *;


@mixin default() {

  @include colour();
  --icon-size: 0.8em;

  position         : relative;
  cursor           : pointer;

  display          : flex;
  flex-wrap        : nowrap;
  grid-gap         : $gap0;
  align-items      : center;

  word-break       : break-all; 
  white-space      : nowrap;
  font-weight      : 600;
  line-height      : 1;
  text-transform   : uppercase;
  overflow         : hidden;

  height           : fit-content;
  padding          : 1.25rem 3rem 1.25rem 4rem;
  font-size        : 16px;

  background       : var(--background-colour);
  color            : var(--font-colour); 

  @include angled-clip();

  // TODO: maybe include a container with a class so that buttons can hold other elements
  // as well as SVGs
  > svg { @include square(var(--icon-size)) }


  &[disabled] { @include disabled() }
  &:not([disabled]):hover { @include hover() }

  &.small { @include small() }
  &.large { @include large() }

  &::before {
    --transition-duration: 0.3s;

    content: '';
    position: absolute;
    top: 0;
    left: calc(-100%);
    width: calc(100% + 15px);
    height: 100%;
    background: var(--background-colour);
    filter: brightness(120%);
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, calc(100% - 15px) 100%);
    z-index: -1;
    transition: transform $transition;
  }
}


@mixin colour($primary : $red, $secondary : white) {
  --background-colour : #{$primary};
  --font-colour : #{$secondary};
}




@mixin small {
  padding   : 0.9rem 2rem 0.9rem 3rem;
  font-size : 12px;
}

@mixin large {
  padding   : 1.4rem 3rem 1.4rem 4.25rem;
  font-size : 18px;
}




@mixin hover() {
  text-decoration-line: none;
  text-decoration-color: transparent;

  &::before {
    transform: translateX(calc(100% - 15px));
  }
}




@mixin clipped() {
  @include angled-clip();
}