@use '_modules' as *;

page-background {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: scale(1.0);

  background: var(--theme-page-background);
  z-index: $z-index-page-background;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--theme-content-background);
    z-index: $z-index-page-background + 1;

    @include break-out('small-medium') {
      top: 0;
      left: calc(var(--navigation-bar-width));
      width: calc(100% - var(--navigation-bar-width));
      height: 100%;
    }
  }

  video{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1);
    user-select: none;
    opacity: 0.3;
  }

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1);
    user-select: none;

    // &.default { object-position: 0px 21vmin }

    &.logo {
      opacity: 0.03;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, transparent 40%, #12152B 100%);
      z-index: $z-index-page-background -1;
    }
  }
}