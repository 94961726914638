////////////////////////////////////////////////////////////////////////////////
// Global Styling
////////////////////////////////////////////////////////////////////////////////

@use '_modules' as *;

@use 'webfonts';
@use 'custom-properties';
@use 'typography';
@use 'layout';
@use 'content';
@use 'forms';


html {
  overflow-x: hidden;
}

body {
  overflow-x: initial;
  background-color: var(--theme-content-background);

  &#search {
    .subheading {
      margin-right: 2rem;
      u { 
        white-space: pre-line;
        word-break: break-word;
      }
    }
  }
}

.d-none {
  display: none !important;
}

figure.image, picture.image {
  @include image-srcset();
}

video-container {
  @include video-container();
}

mark {
  @include mark();
}

details { 
  outline:none;
  &[open] summary > svg { transform: rotate(90deg) }
}
summary {
  -webkit-appearance:none;
  cursor: pointer;
  &::-webkit-details-marker, &::marker { display:none; }
}

.overflow-hidden { overflow : hidden !important }

.hide {
  opacity: 0 !important;
  pointer-events: none !important;
}

.button {
  @include button-default();
}

.button, button {
  svg {
    pointer-events: none;
    vertical-align: middle;
  }
}

picture {
  display: block;
}

.result-item {
  @include result-item-base();
}

.tag {
  @include tag();
}


table-container {
  .heading {
    @include heading-h2;
    margin-bottom: $gap2;
  }
  .table {
    @include table-default();
  }
}

main > .snippet {
  a { --font-colour : var(--theme-accent) }
}

// ============================================================