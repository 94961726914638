@use '_modules' as *;


@font-face {
  font-family:"lato";
  src:url("../../../assets/fonts/lato-bold.woff2") format("woff2"),
      url("../../../assets/fonts/lato-bold.woff")  format("woff"),
      url("../../../assets/fonts/lato-bold.otf")   format("opentype");
      font-display:swap;font-style:normal;font-weight:700;
}

@font-face {
font-family:"lato";
src:url("../../../assets/fonts/lato-semibold.woff2") format("woff2"),
    url("../../../assets/fonts/lato-semibold.woff")  format("woff"),
    url("../../../assets/fonts/lato-semibold.otf")   format("opentype");
    font-display:swap;font-style:normal;font-weight:600;
}

@font-face {
  font-family:"lato";
  src:url("../../../assets/fonts/lato-regular.woff2") format("woff2"),
      url("../../../assets/fonts/lato-regular.woff")  format("woff"),
      url("../../../assets/fonts/lato-regular.otf")   format("opentype");
      font-display:swap;font-style:normal;font-weight:400;
  }

@font-face {
font-family:"montserrat";
src:url("../../../assets/fonts/montserrat-medium.woff2") format("woff2"),
    url("../../../assets/fonts/montserrat-medium.woff")  format("woff"),
    url("../../../assets/fonts/montserrat-medium.otf")   format("opentype");
    font-display:swap;font-style:normal;font-weight:500;
}

@font-face {
font-family:"montserrat";
src:url("../../../assets/fonts/montserrat-medium-italic.woff2") format("woff2"),
    url("../../../assets/fonts/montserrat-medium-italic.woff")  format("woff"),
    url("../../../assets/fonts/montserrat-medium-italic.otf")   format("opentype");
    font-display:swap;font-style:italic;font-weight:500;
}

@font-face {
font-family:"montserrat";
src:url("../../../assets/fonts/montserrat-semibold.woff2") format("woff2"),
    url("../../../assets/fonts/montserrat-semibold.woff")  format("woff"),
    url("../../../assets/fonts/montserrat-semibold.otf")   format("opentype");
    font-display:swap;font-style:normal;font-weight:600;
}

@font-face {
  font-family:"montserrat";
  src:url("../../../assets/fonts/montserrat-regular.woff2") format("woff2"),
      url("../../../assets/fonts/montserrat-regular.woff")  format("woff"),
      url("../../../assets/fonts/montserrat-regular.otf")   format("opentype");
      font-display:swap;font-style:normal;font-weight:400;
}

@font-face {
  font-family:"montserrat";
  src:url("../../../assets/fonts/montserrat-extrabold.woff2") format("woff2"),
      url("../../../assets/fonts/montserrat-extrabold.woff")  format("woff"),
      url("../../../assets/fonts/montserrat-extrabold.otf")   format("opentype");
      font-display:swap;font-style:normal;font-weight:800;
}