@use '../_variables' as *;
@use '../_helpers' as *;

@mixin default() {
  --cell-padding-y : 1rem;
  --cell-padding-x : 1.5rem;
  --cell-padding   : var(--cell-padding-y) var(--cell-padding-x);

  // width: 100%;
  table-layout: fixed;
  text-align: left;

  tr {
    &:nth-child(odd) {
      // TODO: change this to something more semantic
      background-color: var(--theme-content-opposite);
    }

    td:first-child() {
      border-right: 1px solid $grey;
    }
  }

  td {
    padding: var(--cell-padding);
  }

  th {
    padding: var(--cell-padding);
    font-weight: 600;
    border-bottom: 1px solid lightgrey;
  }

  &.vertical {
    tr {
      td:first-child { font-weight: 600 }
    }
  }

  &.product-specs {
    tr {
      td:first-child { white-space: nowrap }
      td:last-child { width: 100%; }
    }
  }

  &.related-products {
    a { --font-colour: #{$red}; }
  }
}