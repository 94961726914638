@use '_modules' as *;

.back-to-top {
  --hide-offset   : -10px;
  --svg-width     : 18px;
  --edge-distance : 15px;
  --right-offset  : 0px;

  display: block;
  position: fixed;
  right: calc(var(--right-offset) + var(--edge-distance));
  bottom: calc(var(--edge-distance) + var(--hide-offset));
  @include square(calc(var(--svg-width) + 12px));
  background: $blue;
  border: 1px solid $white;
  border-radius: 50%;
  z-index: $z-index-main+1;
  // opacity: 0;
  // pointer-events: none;
  pointer-events: all;
  transition: opacity 0.2s $transition, transform 0.2s $transition;

  svg {
    position: relative;
    top: -2px;
    @include square(var(--svg-width));
    color: $white;
  }

  &:not(.hide) {    
    pointer-events: all;
    opacity: 1;
    cursor: pointer;
    transform: translateY(var(--hide-offset));
  }

  display: none;

}

@include break-out(large) {
  side-bar ~ .back-to-top {
    --right-offset: var(--sidebar-width);
  }
}