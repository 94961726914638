@use '_modules' as *;

.heatsinks-home,
.components-home {
  header.header {
    transition: all 0.8s 0.2s;
  }
}

.header {
  align-self: end;
  display: flex;
  flex-direction: column;
  z-index: $z-index-header;

  .heading,
  .subheading {
    text-transform: uppercase;
  }

  .heading {
    order: 2;
    font-weight: 800;
  }

  .subheading {
    color: var(--theme-accent);
    margin-bottom: $gap;

    a {
      --heading-colour: var(--theme-accent);
      // text-decoration-color: var(--theme-accent);
      color: var(--theme-accent);
    }
  }

  .snippet {
    order: 3;
    margin-top: 2rem;

    @include break-out('small-medium') {
      max-width: 1000px;
    }
  }

  &.transition {
    opacity: 0;
    transform: translateY(-10%);
  }
}
