@use '_modules' as *;

side-bar {
	position: relative;
	width: var(--sidebar-width);
	max-width: var(--sidebar-max-width);
	height: 100%;
	z-index: $z-index-main;

	div.content-block.callToAction{
		position: fixed;
		bottom: 0;
		top: unset;
		margin-bottom: 0;
		padding-bottom: 0;

		@media(max-width: 980px){
			right: 0;
		}
		@media(min-width: 980px) and (max-width: 1400px){
			margin-left: calc(clamp(2rem, 5vw, 8rem) * -1);
		}
	}

	@media (max-width: 1400px) {
		margin-bottom: 200px;
		
	}

	&.free {
		--container-padding: 4rem;
	}

	&.contain {
		> div {
			padding-right: var(--gap-x);
		}
	}

	&[data-behaviour="sticky"] {
		--sticky-spacing: 0;

		&.contain {
			--sticky-spacing: var(--gap-y);
		}

		> div {
			position: sticky;
			top: var(--sticky-spacing);
		}

		@include break-in('large') {
			> div {
				position: relative;
				top: 0;
			}
		}
	}


	@include break-in('large') {
		&:not([data-behaviour="foldout"]) {
			--sidebar-max-width: 100%;
			--sidebar-width: 100%;
			
			> div {
				margin-bottom: calc(var(--gap-y) * 2);
			}
		}

		&.contain {
			> div {
				padding: 0;
			}
		}
	}


	&[data-behaviour="foldout"] {
		z-index: $z-index-side-bar;
		transition: max-width 0.7s $ease-in-out, transform $transition-sidebar-open, top $transition-search-open, opacity $transition;

		> div {
			position: sticky;
			top: 0;
		}

		@include break-in('large') {
			position: fixed;
			top: 0;
			right: 0;
			transform: translateX(100%);
			// height: calc(100% + var(--gap-y));
			// Custom props didn't work for some reason.
			// Ideally I would have liked to just set --sidebar-width to this value.
			width: min(calc(100vw), 400px);
			max-width: min(calc(100vw), 400px);
	
			&.active {
				transform: translateX(0%);
			}
	
			> div {
				position: relative;
			}
		}

		@include break-in('small-medium') {
			.open-trigger  { display: none !important }
			.close-trigger { display: block }
		}
	}

}