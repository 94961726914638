@use '_modules' as *;

.content {
  line-height: 1.7;

  ul { @include list-ul($orange) }
  ol { @include list-ol() }

  h3 { @include heading-h2 }

  > * {
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > ul, > ol {
    margin-bottom: 4rem;
  }

  @include heading-all() {
    // margin-top: 1rem;
    margin-bottom: 2.25rem;

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 3rem;
    }
  }

  a { --font-colour: var(--theme-accent) }
}