@use '_modules' as *;


navigation-bar	{
	position: fixed;
  top: 0;
	width: 100%;
	width: var(--navigation-bar-width);
	height: var(--navigation-bar-height);
	max-height: -webkit-fill-available;
	padding: 0 $gap2 var(--gap-y);
	background: var(--theme-nav-background);
	border-right: 1px solid rgba($white, 0.2);
	overflow-y: auto;
	z-index: $z-index-navigation-bar;
	transition: background $transition-nav-background;

	a, button {
		-webkit-tap-highlight-color: $transparent;
	}

	global-search-mobile  { display: none }

	.navigation-container {

		display: flex;
		flex-direction: column;
		@include square(100%);

		a.logo {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			width: 95%;
			min-height: var(--logo-height);
			height: var(--logo-height);
			margin-bottom: var(--gap-y);
	
			> svg {
				width: 100%;
				color: var(--theme-logo);
			}

			&.logo-mobile { display: none }
		}

		global-search-desktop { margin-bottom: $gap2 }
	}

	nav {
		navigation-links {
			--fallback-max-height: 600px;
			--base-spacing: #{$gap0};

			display: block;

			.top-level-link {
				padding: $gap0 0;

				&:first-child { padding-top: 0 }

				&.has-children {
					> a:hover svg { opacity: 1 }
				}
			}

			.sub-menu {
				li {
					padding: calc($gap0 / 2) 0;

					&:first-child { padding-top: $gap0 }
					&:last-child  { padding-bottom: $gap0 }
				}
			}


			ul {
				li {
					a {
						transition: opacity $transition, color $transition, text-decoration-color $transition;

						&:not(.active) {
							--font-colour: var(--theme-nav-font);
						}

						&.active { 
							--font-colour: var(--theme-accent);

							@include hover() {
								text-decoration-color: $transparent !important;
								text-decoration: none;
							}
						}

						> svg {
							color: var(--theme-nav-font);
							transition: opacity $transition, transform $transition;
							width: 1em;
							height: 1em;
							opacity: 0.2;
						}
					}

					ul {
						--transition-duration: 0.325s;

						max-height: 0;
						opacity: 0;
						overflow: hidden;
						transition: max-height $transition, opacity $transition;
						margin-left: $gap;

						li {
							a:not(.active) {
								opacity: 0.5;
								@include hover { opacity: 1 }
							}
						}
					}

					&.has-children {
						> a {
							display: flex;
							justify-content: space-between;
							align-items: center;
						}

						&.open {
							> a > svg {
								transform: rotate(90deg);
							}

							> ul {
								max-height: var(--link-max-height, var(--fallback-max-height));
								opacity: 1;
							}
						}
					}
				}
			}

			.top-level-links {
				> li > a { text-transform: uppercase }
			}

			.mobile-link.hidden { display: none }
		}
	}

	navigation-controls {
		display: none;
		align-items: center;

		& > *:not(:first-child) {
			margin-left: var(--gutter3);
		}

		a, button {
			display: block;
			height: fit-content;

			svg {
				height: 25px;
				width: 25px;
				color: var(--theme-nav-font);
				vertical-align: middle;
			}
		}

		button.menu {
			--symbol-colour: var(--theme-nav-font);

			svg {
				--corner-offset: 10px;

				width:30px;
				height:30px;
		
				polygon {
					clip-path: polygon(140px 20px, 0 20px, var(--corner-offset, 10px) 0, 140px 0);
					transition: all $transition;
					
					&:nth-child(1) {
						transform-origin: 15% 30%;
					}
		
					&:nth-child(2) {
						opacity: 1;
					}
		
					&:nth-child(3) {
						transform-origin: 15% 70%
					}
				}
			}
		}
	}

	.extra-links {
		--font-colour: var(--theme-nav-font);
		--link-spacing: #{$gap};
		--opacity: 0.6;

		display: flex;
		font-size: $font-size-min;
		opacity: var(--opacity);
		margin-top: auto;
		flex-wrap: wrap;
		gap:1rem;

		// li + li {
		li:not(:last-of-type) {
			position: relative;
			display: inline-flex;
			align-items: center;
			gap:1ch;
			min-width: fit-content;
		}

		a {
			text-decoration: none;
			white-space: nowrap;
			@include hover { text-decoration: underline }
		}
	}
}





@include break-in('small-medium') {

	navigation-bar {

		--navigation-bar-width: 100%;
		--navigation-bar-height: min-content;
		--logo-height: 100%;
		--row-padding: 1rem var(--gap-x);

		--theme-nav-font: var(--theme-nav-font-mob);
		--theme-logo: var(--theme-nav-font-mob);

		position: relative;
		padding: 0;
		overflow: visible;
		border-right: none;


		.extra-links { display: none }
		global-search-desktop	 { display: none }

		.navigation-container, global-search-mobile {
			background-color: var(--theme-nav-background-mob);
		}

		global-search-mobile  {
			position: relative;
			display: block;
			padding: var(--row-padding);
			height: var(--navigation-mobile-search-height);
			margin-top: calc(-1 * var(--navigation-mobile-search-height) - 0px);
			transition: margin-top $transition-search-open;
			border-bottom: 1px solid var(--theme-nav-spacing-colour);
			z-index: $z-index-global-search-mobile;

			form {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;

				input[type="search"] { border-right: none; }

				select {
					--font-colour: #{$white};
					--background-colour: #{$red};
					@include form-arrow-down-image($white);
				}
			}
		}

		navigation-controls {
			display: flex;
			margin-bottom: 0;
		}

		.navigation-container {
			flex-direction: row;
			justify-content: space-between;
			height: var(--navigation-container-height);
			padding: var(--row-padding);
			border-bottom: 1px solid var(--theme-nav-spacing-colour);
			z-index: $z-index-navigation-bar-container;
			
			a.logo {
				margin-bottom: 0;
				width: fit-content;

				&.logo-desktop { display: none }
				&.logo-mobile  { 
					display: block;
					margin-left: -3rem;
				}
	
				svg { 
					width: unset;
					height: 100%;
				}
			}
		}

		nav {
			position: absolute;
			top: calc(100% + 0px);
			left: 0;
			width: 100%;
			height: 100vh;
			padding: var(--gap-y) var(--gap-x) var(--navigation-container-height);
			background: var(--theme-nav-background-mob);
			overflow-y: auto;
			opacity: 0;
			pointer-events: none;
			transition: opacity $transition-nav-background;

			.top-level-links { opacity: 0 }
		}
	}






	// Activated components

	body {
		&.mobile-search-open {
			navigation-bar {
				global-search-mobile { margin-top: 0 }
			}
		}
	
		&.mobile-navigation-open {
			navigation-bar {
				// background: var(--activated-background);

				nav {
					pointer-events: all;
					opacity: 1;

					.top-level-links {
						opacity: 1;
						transition: all 0.3s $ease-out-quart 0.3s;
					}
				}

				button.menu {
					svg {
						--corner-offset: 0;
				
						polygon {
							&:nth-child(1) {
								transform: rotate(45deg) scaleY(80%);
								-webkit-transform: rotate(45deg) scaleY(80%);
							}
				
							&:nth-child(2) {
								opacity: 0;
							}
				
							&:nth-child(3) {
								transform: rotate(-45deg) scaleY(80%);
								-webkit-transform: rotate(-45deg) scaleY(80%);
							}
						}
					}
				}
			}
		}
  }
}